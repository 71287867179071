<template>
    <div class="container">
        <div>
            <h1>Sign in</h1>
            <a-form
                class="login-form"
                @submit="do_login"
                layout="horizontal"
            >
                <a-form-item label="User Name">
                    <a-input v-model="user_name"/>
                </a-form-item>
                <a-form-item label="Password">
                    <a-input type="password" v-model="pswd"/>
                </a-form-item>
                <a-form-item>
                    <a-button type="primary" html-type="submit" class="login-form-button">
                        Sign in
                    </a-button>
                </a-form-item>
            </a-form>
        </div>
    </div>
    
</template>
<style scoped>
.container{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
    box-sizing: border-box;
}
.login-form {
  width: 300px;
}
.login-form-button {
  width: 100%;
}
</style>
<script>
    import md5 from 'md5';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
    export default {
        name: 'login',
        data() {
            return {
                user_name: '',
                pswd: ''
            };
        },
        beforeMount() {
            let _token = window.localStorage.getItem('token') || '';
            if ( _token) {
                this.$router.replace({ path: "/welcome" });
                // post_data.router.replace({ path: '/business' });
            }
        },
        methods: {
            do_login() {
                if (!this.user_name || !this.pswd) {
                    this.$message.warning('Please input your Email Address And Password');
                    return;
                }
                this.do_login_aj({
                    user_name: this.user_name,
                    pswd: md5(this.pswd),
                    router:this.$router
                });
            },
            ...mapActions({
                do_login_aj: 'login/do_login_aj'
            })
        }
    }
</script>