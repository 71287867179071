import fetchApi from '../../kits/newFetch';
import { message } from 'ant-design-vue'

// initial state
const state = {
    msg_opts: '',
    loading: false,
    content: {},
    duty_content: {},
    bill_content: {},
    bill: {}
};

// getters
const getters = {
    getLoading(state) {
        return state.loading;
    },
    getConetent(state) {
        return JSON.parse(JSON.stringify(state.content));
    },
    getDutyContent(state) {
        return JSON.parse(JSON.stringify(state.duty_content));
    },
    getBillContent(state) {
        return JSON.parse(JSON.stringify(state.bill_content));
    },
    getBill(state) {
        return JSON.parse(JSON.stringify(state.bill));
    },
    get_app_msg(state) {
        return state.msg_opts;
    }
};

// actions
const actions = {
    initData({ commit, rootState, rootGetters }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:8181' : '';
        fetchApi(
            { commit },
            {
                request_name: 'get_business_table',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                if (res && res.data && res.data.data) {
                    let result = res.data.data.data;
                    const sids = result.map(item => {
                        return item.sid;
                    })
                    if (sids.length > 0) {
                        fetchApi(
                            { commit },
                            {
                                request_name: 'get_duty_count',
                                params: { sids: sids, status: 0 },
                                aj_host
                            }
                        )
                            .then((duty_conut_res) => {
                                if (duty_conut_res && duty_conut_res.data && duty_conut_res.data.data) {
                                    let duty_conut_result = duty_conut_res.data.data.data;
                                    result = result.map(item => {
                                        duty_conut_result.map(c_item => {
                                            if (item.sid === c_item.sid) {
                                                item.duty_count = c_item.count;
                                            }
                                        })
                                        return item;
                                    })
                                    commit("APP_SET_CONTENT", {
                                        content: {
                                            data: result,
                                            total: res.data.data.total,
                                            totalAmount: res.data.data.totalAmount,
                                            pageNo: res.data.data.pageNo,
                                            pageSize: res.data.data.pageSize
                                        }
                                    });
                                    post_data.cb && post_data.cb();
                                }
                            }).catch((e) => {
                                console.log(e);
                            });
                    } else {
                        commit("APP_SET_CONTENT", {
                            content: res.data.data
                        });
                        post_data.cb && post_data.cb();
                    }
                }
            }).catch((e) => {
                console.log(e);
            });
    },
    updateTable({ commit, rootState, rootGetters }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:8181' : '';
        fetchApi(
            { commit },
            {
                request_name: 'update_business_table',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                if (res.data.code == 100000) {
                    message.success('update is success!');
                    actions.initData({ commit, rootState, rootGetters }, post_data.afterdata)
                } else {
                    message.success('update is failed!');
                }
            }).catch((e) => {
                message.success('update is failed!');
                console.log(e);
            });
    },
    updateStatus({ commit, rootState, rootGetters }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:8181' : '';
        fetchApi(
            { commit },
            {
                request_name: 'update_status',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                if (res.data.code == 100000) {
                    message.success('update is success!');
                    actions.initData({ commit, rootState, rootGetters }, post_data.afterdata);
                    post_data.cb && post_data.cb();
                } else {
                    message.success('update is failed!');
                }
            }).catch((e) => {
                message.success('update is failed!');
                console.log(e);
            });
    },
    initDutyData({ commit, rootState, rootGetters }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:8181' : '';
        fetchApi(
            { commit },
            {
                request_name: 'get_duty_table',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                if (res && res.data && res.data.data) {
                    commit("APP_SET_DUTY_CONTENT", {
                        duty_content: res.data.data
                    });

                    post_data.cb && post_data.cb();
                }
            }).catch((e) => {
                console.log(e);
            });
    },
    addDuty({ commit, rootState, rootGetters }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:8181' : '';
        fetchApi(
            { commit },
            {
                request_name: 'add_duty',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                if (res.data.code == 100000) {
                    message.success('add is success!');
                    actions.initDutyData({ commit, rootState, rootGetters }, post_data.afterdata)
                    post_data.cb && post_data.cb();
                } else {
                    message.success('add is failed!');
                }
            }).catch((e) => {
                message.success('add is failed!');
                console.log(e);
            });
    },
    updateDuty({ commit, rootState, rootGetters }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:8181' : '';
        fetchApi(
            { commit },
            {
                request_name: 'update_duty',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                if (res.data.code == 100000) {
                    message.success('update is success!');
                    actions.initDutyData({ commit, rootState, rootGetters }, post_data.afterdata);
                    post_data.cb && post_data.cb();
                } else {
                    message.success('update is failed!');
                }
            }).catch((e) => {
                message.success('update is failed!');
                console.log(e);
            });
    },
    updateDutyStatus({ commit, rootState, rootGetters }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:8181' : '';
        fetchApi(
            { commit },
            {
                request_name: 'update_duty_status',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                if (res.data.code == 100000) {
                    message.success('update is success!');
                    actions.initDutyData({ commit, rootState, rootGetters }, post_data.afterdata);
                    post_data.cb && post_data.cb();
                } else {
                    message.success('update is failed!');
                }
            }).catch((e) => {
                message.success('update is failed!');
                console.log(e);
            });
    },
    synchronStore({ commit, rootState, rootGetters }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:8181' : '';
        fetchApi(
            { commit },
            {
                request_name: 'synchron_store',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                if (res.data.code == 100000) {
                    message.success('synchron is success!');
                } else {
                    message.success('synchron is failed!');
                }
            }).catch((e) => {
                message.success('synchron is failed!');
            });
    },
    initBillData({ commit, rootState, rootGetters }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:8181' : '';
        fetchApi(
            { commit },
            {
                request_name: 'get_bill_table',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                if (res && res.data && res.data.data) {
                    commit("APP_SET_BILL_CONTENT", {
                        bill_content: res.data.data
                    });
                }
            }).catch((e) => {
                console.log(e);
            });
    },
    addBill({ commit, rootState, rootGetters }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:8181' : '';
        fetchApi(
            { commit },
            {
                request_name: 'add_bill',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                if (res.data.code == 100000) {
                    message.success('add is success!');
                    actions.initData({ commit, rootState, rootGetters }, post_data.afterdata)
                    post_data.cb && post_data.cb();
                } else {
                    message.success('add is failed!');
                }
            }).catch((e) => {
                message.success('add is failed!');
                console.log(e);
            });
    },
    updateBill({ commit, rootState, rootGetters }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:8181' : '';
        fetchApi(
            { commit },
            {
                request_name: 'update_bill',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                if (res.data.code == 100000) {
                    message.success('update is success!');
                    actions.initData({ commit, rootState, rootGetters }, post_data.afterdata);
                    post_data.cb && post_data.cb();
                } else {
                    //message.fail('update is failed!');
                }
            }).catch((e) => {
                // message.success('update is failed!');
                console.log(e);
            });
    },
    getBill({ commit, rootState, rootGetters }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:8181' : '';
        fetchApi(
            { commit },
            {
                request_name: 'get_bill_by_id',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                if (res && res.data && res.data.data) {
                    commit("APP_SET_BILL", {
                        bill: res.data.data
                    });
                }
            }).catch((e) => {
                console.log(e);
            });
    },

    delBill({ commit, rootState, rootGetters }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:8181' : '';
        fetchApi(
            { commit },
            {
                request_name: 'delete_bill',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                if (res && res.data && res.data.data) {
                    message.success('delete is success!');
                    actions.initData({ commit, rootState, rootGetters }, post_data.afterdata);
                    post_data.cb && post_data.cb();
                }
            }).catch((e) => {
                message.success('delete is failed!');
                console.log(e);
            });
    },
};

// mutations
const mutations = {
    APP_SET_IF_LOADING(state, _data) {
        state.is_loading = _data.is_loading;
    },
    APP_SET_CONTENT(state, _data) {
        state.content = _data.content;
    },
    APP_SET_DUTY_CONTENT(state, _data) {
        state.duty_content = _data.duty_content;
    },
    APP_SET_BILL_CONTENT(state, _data) {
        state.bill_content = _data.bill_content;
    },
    APP_SET_BILL(state, _data) {
        state.bill = _data.bill;
    },
    APP_SET_GLOBAL_DAT(state, _data) {
        state.sid = _data.sid;
        state.uid = _data.uid;
        state.role = _data.role;
        state.sname = _data.sname;
        state.slogo = _data.slogo;
        state.token = _data.token;
    },
    APP_SET_MSG(state, _data) {
        state.msg_opts = _data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    strict: true
};
