<template>
    <div class="store-list">
        <!-- 搜索区域 -->
        <div class="search-box" :class="{'down':down}">
            <div v-for="(item,index) in searchItems" :key="index">
                <template v-if="item.hasOwnProperty('type')">
                    <div v-if="item.type=='input'" class="search-items">
                        <span class="label" :title="item.title">{{item.title}}:</span>
                        <a-input v-model="searchList[item.dataIndex]"></a-input>
                    </div>
                    <div v-if="item.type=='select'" class="search-items">
                        <span class="label" :title="item.title">{{item.title}}:</span>
                        <a-select v-if="item.dataIndex=='resetaurant_type'" mode="multiple" style="width: 100%" v-model="searchList[item.dataIndex]">
                            <template v-for="its in resetaurant_type">
                                <template v-if="its.children && its.children.length">
                                    <a-select-opt-group :label="its.label" :key="its.value">
                                        <a-select-option v-for="it in its.children" :key="it.value">
                                            {{it.label}}
                                        </a-select-option>
                                    </a-select-opt-group>
                                </template>
                                <template v-else>
                                    <a-select-option :key="its.value">
                                        {{its.label}}
                                    </a-select-option>
                                </template>
                            </template>
                        </a-select>
                        <a-select v-else-if="item.dataIndex=='ethnicity'" style="width: 100%" v-model="searchList[item.dataIndex]">
                            <a-select-option v-for="its in ethnicity" :key="its.value">
                                {{its.label}}
                            </a-select-option>
                        </a-select>
                        <a-select v-else-if="item.dataIndex=='service'" mode="multiple" style="width: 100%" v-model="searchList[item.dataIndex]">
                            <a-select-option v-for="its in service" :key="its.value">
                                {{its.label}}
                            </a-select-option>
                        </a-select>
                        <a-select v-else-if="item.dataIndex=='contract_type'" style="width: 100%" v-model="searchList[item.dataIndex]">
                            <a-select-option v-for="its in contract_type" :key="its.value">
                                {{its.label}}
                            </a-select-option>
                        </a-select>
                        <a-select v-else-if="item.dataIndex=='business_status'" style="width: 100%" v-model="searchList[item.dataIndex]">
                            <a-select-option v-for="its in business_status" :key="its.value">
                                {{its.label}}
                            </a-select-option>
                        </a-select>
                        <a-select v-else-if="item.dataIndex=='online_status'" style="width: 100%" v-model="searchList[item.dataIndex]">
                            <a-select-option v-for="its in online_status" :key="its.value">
                                {{its.label}}
                            </a-select-option>
                        </a-select>
                        <a-select v-else-if="item.dataIndex=='contract_status'" style="width: 100%" v-model="searchList[item.dataIndex]">
                            <a-select-option v-for="its in contract_status" :key="its.value">
                                {{its.label}}
                            </a-select-option>
                        </a-select>
                        <a-select v-else-if="item.dataIndex=='payment_status'" style="width: 100%" v-model="searchList[item.dataIndex]">
                            <a-select-option v-for="its in payment_status" :key="its.value">
                                {{its.label}}
                            </a-select-option>
                        </a-select>
                    </div>
                    <div v-if="item.type=='date'" class="search-items" >
                        <span class="label" :title="item.title">{{item.title}}:</span>
                        <a-range-picker v-model="searchList[item.dataIndex]"/>
                    </div>
                </template>
            </div>
            <div class="search-items search-btn" :class="{'down':down}">
                <a-button type="link" v-if="!down" @click="down = !down">
                    <span>展开</span>
                    <a-icon type="down" />
                </a-button>
                <a-button type="link" v-if="down" @click="down = !down">
                    <span>收起</span>
                    <a-icon type="up" />
                </a-button>
                <a-button type="primary" @click="handleTableSearch">Search</a-button>
                <a-button type="default" @click="handleTableReset">Reset</a-button>
            </div>
        </div>
        <div class="btn-box">
            <a-button type="primary" @click="handleSynchronStore">同步商户信息</a-button>
            <a-button style="margin-left: 10px" type="info" @click="goToNewPage">新页面(beta)</a-button>
        </div>
        <!-- 表格区域 -->
        <div class="table-box">
            <a-table
                :columns="columns"
                :data-source="content.data"
                :pagination="pagination"
                :rowKey="record=>record.id"
                bordered
                size="middle"
                width="100%"
                @change="handleTableChange"
                :scroll="{ x: 1000,y:435}"
            >
                <template slot="online_status"  slot-scope="text,record">
                    <span v-html="onlineStatus(record)"></span>
                </template>
                <template slot="contract_status"  slot-scope="text,record">
                    <span v-html="contractStatus(record)"></span>
                </template>
                <template slot="payment_status"  slot-scope="text,record">
                    <span v-html="paymentStatus(record)"></span>
                </template>
                <template slot="business_status"  slot-scope="text,record">
                    <span v-html="businessStatus(record)"></span>
                </template>
                <template slot="expenses_all"  slot-scope="text,record">
                    <span v-html="expensesAll(record)"></span>
                </template>
                <template slot="operation"  slot-scope="text, record">
                    <a-button type="link" @click="() => handleSetting(record)">edit</a-button>
                </template>
                
            </a-table>
        </div>
        <a-drawer
            title="Setting"
            placement="right"
            :visible="visible"
            :body-style="{ paddingBottom: '80px' }"
            @close="onClose"
            :width="900"
        >
            <div class="search">
                <div v-for="(item,index) in searchItems" :key="index">
                    <template v-if="item.hasOwnProperty('type')">
                        <div v-if="item.type=='input' && !item.hidden" class="search-items">
                            <span class="label" style="min-width: 228px;" :title="item.title">{{item.title}}:</span>
                            <a-input :disabled="item.disabled" v-model="setObj[item.dataIndex]"></a-input>
                        </div>
                        <div v-if="item.type=='select'" class="search-items">
                            <span class="label" style="min-width: 228px;" :title="item.title">{{item.title}}:</span>
                            <a-select v-if="item.dataIndex=='resetaurant_type'" mode="multiple" style="width: 100%" v-model="setObj[item.dataIndex]">
                                <!-- <a-select-option v-for="i in 25" :key="(i + 9).toString(36) + i">
                                    {{ (i + 9).toString(36) + i }}
                                </a-select-option> -->
                                <template v-for="its in resetaurant_type">
                                    <template v-if="its.children && its.children.length">
                                        <a-select-opt-group :label="its.label" :key="its.value">
                                            <a-select-option v-for="it in its.children" :key="it.value">
                                                {{it.label}}
                                            </a-select-option>
                                        </a-select-opt-group>
                                    </template>
                                    <template v-else>
                                        <a-select-option :key="its.value">
                                            {{its.label}}
                                        </a-select-option>
                                    </template>
                                </template>
                            </a-select>
                            <a-select v-else-if="item.dataIndex=='ethnicity'" style="width: 100%" v-model="setObj[item.dataIndex]">
                                <a-select-option v-for="its in ethnicity" :key="its.value">
                                    {{its.label}}
                                </a-select-option>
                            </a-select>
                            <a-select v-else-if="item.dataIndex=='service'" mode="multiple" style="width: 100%" v-model="setObj[item.dataIndex]">
                                <a-select-option v-for="its in service" :key="its.value">
                                    {{its.label}}
                                </a-select-option>
                            </a-select>
                            <a-select v-else-if="item.dataIndex=='contract_type'" style="width: 100%" v-model="setObj[item.dataIndex]">
                                <a-select-option v-for="its in contract_type" :key="its.value">
                                    {{its.label}}
                                </a-select-option>
                            </a-select>
                            <a-select v-else-if="item.dataIndex=='business_status'" style="width: 100%" v-model="setObj[item.dataIndex]">
                                <a-select-option v-for="its in business_status" :key="its.value">
                                    {{its.label}}
                                </a-select-option>
                            </a-select>
                            <a-select v-else-if="item.dataIndex=='online_status'" style="width: 100%" v-model="setObj[item.dataIndex]">
                                <a-select-option v-for="its in online_status" :key="its.value">
                                    {{its.label}}
                                </a-select-option>
                            </a-select>
                            <a-select v-else-if="item.dataIndex=='contract_status'" style="width: 100%" v-model="setObj[item.dataIndex]">
                                <a-select-option v-for="its in contract_status" :key="its.value">
                                    {{its.label}}
                                </a-select-option>
                            </a-select>
                            <a-select v-else-if="item.dataIndex=='payment_status'" style="width: 100%" v-model="setObj[item.dataIndex]">
                                <a-select-option v-for="its in payment_status" :key="its.value">
                                    {{its.label}}
                                </a-select-option>
                            </a-select>
                        </div>
                        <div v-if="item.type=='date'" class="search-items">
                            <span class="label" style="min-width: 228px;" :title="item.title">{{item.title}}:</span>
                            <a-date-picker style="width: 240px;" v-model="setObj[item.dataIndex]"/>
                        </div>
                        <div v-if="item.type=='textarea'" class="search-items">
                            <span class="label" style="min-width: 228px;" :title="item.title">{{item.title}}:</span>
                            <a-textarea style="width: 240px;" v-model="setObj[item.dataIndex]" />
                        </div>
                    </template>
                </div>
            </div>
            <div
                :style="{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
                zIndex: 1,
                }"
            >
                <a-button :style="{ marginRight: '8px' }" @click="onClose">
                    Cancel
                </a-button>
                <a-button type="primary" @click="handleSubmit">
                    Submit
                </a-button>
            </div>
        </a-drawer>
        <div class="loading_layer" v-if="is_loading">
            <a-spin/>
        </div>
    </div>
</template>
<style scoped>
.loading_layer {
    text-align: center;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0%;
    left: 0%;
    line-height: 500px;
    background: #fff;
    z-index: 100;
    opacity: 0.5;
}
.store-list{
    padding: 20px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #f5f5f5;
}
.search-box{
    height: 72px;
    overflow:hidden;
    border-radius: 5px;
    padding: 20px;
    background: #fff;
    margin-bottom: 20px;
    display:flex;
    flex-wrap:wrap;
    padding-right: 200px;
    position: relative;
}
.search-box.down{
    height: auto;
    padding-right: 20px;
    padding-bottom: 52px;
}
.search-items{
    margin-right: 10px;
    width: 500px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.search-btn{
    position: absolute;
    right: 0;
    bottom: 50%;
    margin-bottom: -16px;
    display: flex;
    justify-content: flex-end;
}
.search-btn.down{
    bottom: 0;
    margin-bottom: 20px;
}
.search-btn .ant-btn{
    margin: 0 10px;
}
.label{
    min-width: 100px;
    text-align: right;
    padding-right: 10px;
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
}
.table-box{
    padding: 20px;
    background: #fff;
    border-radius: 5px;
}
.btn-box{
    padding: 20px 0 0 20px;
    background: #fff;
}
</style>

<script>
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
    
    export default {
        name: 'welcome',
        data() {
            return {
                is_loading: false,
                down:false,
                visible:false,
                setObj:{},
                searchList:{
                    sname:undefined,
                    user_name:undefined,
                    online_status:undefined,
                    contract_status:undefined,
                    payment_status:undefined,
                    gm_name:undefined,
                    // contact_method:undefined,
                    phone_num:undefined,
                    wechat:undefined,
                    email:undefined,
                    zip_code:undefined,
                    state:undefined,
                    resetaurant_type:undefined,
                    ethnicity:undefined,
                    service:undefined,
                    sales_manager:undefined,
                    contract_type:undefined,
                    contract_price:undefined,
                    contract_time:[],
                    contract_stime:[],
                    contract_etime:[],
                    free_trail_start_time:[],
                    free_trail_end_time:[],
                    give_month:undefined,
                    official_charging_time:[],
                    equipment_expenses_spent:undefined,
                    equipment_expenses_charge:undefined,
                    material_expenses_spent:undefined,
                    material_expenses_charge:undefined,
                    other_expenses_spent:undefined,
                    other_expenses_charge:undefined,
                    business_status:undefined,
                    shoud_charge_without_tax:undefined,
                    real_charge:undefined,
                    bank_fees:undefined,
                    balance:undefined,
                    charge_platform:undefined,
                    remark:undefined
                },
                searchItems:[],
                columns:[
                    {
                        title:'#',
                        dataIndex:'rank',
                        width:50,
                        fixed: 'left'
                    },
                    {
                        title:'商户名字',
                        dataIndex:'sname',
                        key:'sname',
                        width:200,
                        ellipsis: true,
                        type:'input',
                        disabled:true,
                        fixed: 'left'
                    },
                    {
                        title:'商户基本信息',
                        children:[
                            {
                                title:'Email',
                                dataIndex:'user_name',
                                key:'user_name',
                                width:200,
                                ellipsis: true,
                                disabled:true,
                                type:'input'
                            },
                            {
                                title:'上线状态', 
                                dataIndex:'online_status',
                                key:'online_status',
                                width:200,
                                ellipsis: true,
                                scopedSlots: { customRender: 'online_status' },
                                type:'select'
                            },
                            {
                                title:'合同状态', 
                                dataIndex:'contract_status',
                                key:'contract_status',
                                width:200,
                                ellipsis: true,
                                scopedSlots: { customRender: 'contract_status' },
                                type:'select'
                            },
                            {
                                title:'付款状态', 
                                dataIndex:'payment_status',
                                key:'payment_status',
                                width:200,
                                ellipsis: true,
                                scopedSlots: { customRender: 'payment_status' },
                                type:'select'
                            },
                            {
                                title:'Owner/GM name (optional)',
                                dataIndex:'gm_name',
                                key:'gm_name',
                                width:200,
                                ellipsis: true,
                                type:'input'
                            },
                            // {
                            //     title:'Contact Phone/Wechat/Email/Others (optional)',
                            //     dataIndex:'contact_method',
                            //     key:'contact_method',
                            //     width:320,
                            //     ellipsis: true,
                            //     type:'input'
                            // },
                            {
                                title:'Phone Number',
                                dataIndex:'phone_num',
                                key:'phone_num',
                                width:200,
                                ellipsis: true,
                                type:'input'
                            },
                            {
                                title:'微信',
                                dataIndex:'wechat',
                                key:'wechat',
                                width:200,
                                ellipsis: true,
                                type:'input'
                            },
                            {
                                title:'Email',
                                dataIndex:'email',
                                key:'email',
                                width:200,
                                ellipsis: true,
                                type:'input'
                            },
                            {
                                title:'Zip Code',
                                dataIndex:'zip_code',
                                key:'zip_code',
                                width:100,
                                ellipsis: true,
                                type:'input'
                            },
                            {
                                title:'State',
                                dataIndex:'state',
                                key:'state',
                                width:200,
                                ellipsis: true,
                                type:'input'
                            },
                            {
                                title:'Restaurant Type',
                                dataIndex:'resetaurant_type',
                                key:'resetaurant_type',
                                width:150,
                                ellipsis: true,
                                type:'select'
                            },
                            {
                                title:'Ethnicity',
                                dataIndex:'ethnicity',
                                key:'ethnicity',
                                width:100,
                                ellipsis: true,
                                type:'select'
                            },
                            {
                                title:'Service',
                                dataIndex:'service',
                                key:'service',
                                width:100,
                                ellipsis: true,
                                type:'select'
                            }
                        ]
                    },
                    {
                        title:'商户销售周期与合约信息',
                        children:[
                            {
                                title:'销售负责人',
                                dataIndex:'sales_manager',
                                key:'sales_manager',
                                width:100,
                                ellipsis: true,
                                type:'input'
                            },
                            {
                                title:'Contract type',
                                dataIndex:'contract_type',
                                key:'contract_type',
                                width:150,
                                ellipsis: true,
                                type:'select'
                            },
                            {
                                title:'Contract Price',
                                dataIndex:'contract_price',
                                key:'contract_price',
                                width:150,
                                ellipsis: true,
                                type:'input'
                            },
                            {
                                title:'合作开始日期',
                                dataIndex:'contract_time',
                                key:'contract_time',
                                width:150,
                                ellipsis: true,
                                type:'date'
                            },
                            {
                                title:'试用期开始日期',
                                dataIndex:'free_trail_start_time',
                                key:'free_trail_start_time',
                                width:150,
                                ellipsis: true,
                                type:'date'
                            },
                            {
                                title:'试用期结束日期',
                                dataIndex:'free_trail_end_time',
                                key:'free_trail_end_time',
                                width:150,
                                ellipsis: true,
                                type:'date'
                            },
                            {
                                title:'合约生效日期',
                                dataIndex:'contract_stime',
                                key:'contract_stime',
                                width:150,
                                ellipsis: true,
                                type:'date'
                            },
                            {
                                title:'合约结束日期',
                                dataIndex:'contract_etime',
                                key:'contract_etime',
                                width:150,
                                ellipsis: true,
                                type:'date'
                            },
                            {
                                title:'合同送月数量',
                                dataIndex:'give_month',
                                key:'give_month',
                                width:150,
                                ellipsis: true,
                                type:'input'
                            },
                            // {
                            //     title:'正式收费开始日期',
                            //     dataIndex:'official_charging_time',
                            //     key:'official_charging_time',
                            //     width:150,
                            //     ellipsis: true,
                            //     type:'date'
                            // }
                        ]
                    },
                    {
                        title:'商户安装成本',
                        children:[
                            {
                                title:'应收设备费用',
                                dataIndex:'equipment_expenses_spent',
                                key:'equipment_expenses_spent',
                                width:150,
                                ellipsis: true,
                                type:'input'
                            },
                            {
                                title:'实收设备费用',
                                dataIndex:'equipment_expenses_charge',
                                key:'equipment_expenses_charge',
                                width:150,
                                ellipsis: true,
                                type:'input'
                            },
                            {
                                title:'应收物料费用',
                                dataIndex:'material_expenses_spent',
                                key:'material_expenses_spent',
                                width:150,
                                ellipsis: true,
                                type:'input'
                            },
                            {
                                title:'实收物料费用',
                                dataIndex:'material_expenses_charge',
                                key:'material_expenses_charge',
                                width:150,
                                ellipsis: true,
                                type:'input'
                            },
                            {
                                title:'应收其他费用',
                                dataIndex:'other_expenses_spent',
                                key:'other_expenses_spent',
                                width:150,
                                ellipsis: true,
                                type:'input'
                            },
                            {
                                title:'实收其他费用',
                                dataIndex:'other_expenses_charge',
                                key:'other_expenses_charge',
                                width:150,
                                ellipsis: true,
                                type:'input'
                            },
                            {
                                title:'总计',
                                dataIndex:'expenses_all',
                                key:'expenses_all',
                                width:100,
                                scopedSlots: { customRender: 'expenses_all' },
                                ellipsis: true
                            }
                        ]
                    },
                    // {
                    //     title:'商户上线状态',
                    //     dataIndex:'business_status',
                    //     key:'business_status',
                    //     width:150,
                    //     ellipsis: true,
                    //     scopedSlots: { customRender: 'business_status' },
                    //     type:'select'
                    // },
                    {
                        title:'商户付费信息',
                        children:[
                            {
                                title:'税前应收',
                                dataIndex:'shoud_charge_without_tax',
                                key:'shoud_charge_without_tax',
                                width:150,
                                ellipsis: true,
                                type:'input'
                            },
                            {
                                title:'实收',
                                dataIndex:'real_charge',
                                key:'real_charge',
                                width:100,
                                ellipsis: true,
                                type:'input'
                            },
                            {
                                title:'银行手续费',
                                dataIndex:'bank_fees',
                                key:'bank_fees',
                                width:100,
                                ellipsis: true,
                                type:'input'
                            },
                            {
                                title:'Balance',
                                dataIndex:'balance',
                                key:'balance',
                                width:100,
                                ellipsis: true,
                                type:'input',
                                hidden: true
                            },
                            {
                                title:'处理收账平台(stripe/quickbooks)',
                                dataIndex:'charge_platform',
                                key:'charge_platform',
                                width:240,
                                ellipsis: true,
                                type:'input'
                            }
                        ]
                    },
                    {
                        title:'备注',
                        key:'remark',
                        dataIndex:'remark',
                        ellipsis: true,
                        width:200,
                        type:'textarea'
                    },
                    {
                        title:'Operation',
                        key:'set',
                        dataIndex:'set',
                        scopedSlots: { customRender: 'operation' },
                        width:100,
                        fixed: 'right'
                    }
                ],
                resetaurant_type:[
                    {
                        label:'Chinese',
                        value:'Chinese',
                        children:[
                            {
                                label:'Szechuan',
                                value:'Szechuan',
                            },
                            {
                                label:'Cantonese',
                                value:'Cantonese',
                            },
                            {
                                label:'Dim Sum',
                                value:'Dim Sum',
                            },
                            {
                                label:'Hunan Cuisine',
                                value:'Hunan Cuisine',
                            },
                            {
                                label:'American Chinese',
                                value:'American Chinese',
                            },
                            {
                                label:'Other Chinese',
                                value:'Other Chinese',
                            }
                        ]
                    },
                    {
                        label:'Hot Pot',
                        value:'Hot Pot',
                    },
                    {
                        label:'BBQ',
                        value:'BBQ'
                    },
                    {
                        label:'Japanese',
                        value:'Japanese',
                        children:[
                            {
                                label:'Sushi Bar',
                                value:'Sushi Bar',
                            },
                            {
                                label:'Ramen',
                                value:'Ramen',
                            },
                            {
                                label:'Hibachi',
                                value:'Hibachi',
                            },
                            {
                                label:'Other Japanese',
                                value:'Other Japanese',
                            }
                        ]
                    },
                    {
                        label:'Halal',
                        value:'Halal',
                    },
                    {
                        label:'Korean Food',
                        value:'Korean Food'
                    },
                    {
                        label:'Vietnamese',
                        value:'Vietnamese',
                    },
                    {
                        label:'Thai',
                        value:'Thai'
                    },
                    {
                        label:'Asian Fusion',
                        value:'Asian Fusion',
                    },
                    {
                        label:'French',
                        value:'French'
                    },
                    {
                        label:'Italian',
                        value:'Italian',
                    },
                    {
                        label:'Spanish',
                        value:'Spanish'
                    },
                    {
                        label:'Mexican',
                        value:'Mexican',
                    },
                    {
                        label:'Seafood',
                        value:'Seafood'
                    },
                    {
                        label:'Steakhouses',
                        value:'Steakhouses',
                    },
                    {
                        label:'American',
                        value:'American'
                    },
                    {
                        label:'Breakfast & Brunch',
                        value:'Breakfast & Brunch',
                    },
                    {
                        label:'Burger & Sandwiches',
                        value:'Burger & Sandwiches'
                    },
                    {
                        label:'Coffee & Tea',
                        value:'Coffee & Tea',
                    },
                    {
                        label:'Desserts',
                        value:'Desserts'
                    },
                    {
                        label:'Bars',
                        value:'Bars',
                    },
                    {
                        label:'Karaoke',
                        value:'Karaoke'
                    },
                    {
                        label:'Spa & Salon',
                        value:'Spa & Salon',
                    }
                ],
                ethnicity:[
                    {
                        label:'Chinese',
                        value:'Chinese'
                    },
                    {
                        label:'Korean',
                        value:'Korean'
                    },
                    {
                        label:'Japanese',
                        value:'Japanese'
                    },
                    {
                        label:'Asian',
                        value:'Asian'
                    },
                    {
                        label:'American',
                        value:'American'
                    },
                    {
                        label:'African American',
                        value:'African American'
                    },
                    {
                        label:'European',
                        value:'European'
                    },
                    {
                        label:'Hispanic',
                        value:'Hispanic'
                    },
                    {
                        label:'American Indian',
                        value:'American Indian'
                    },
                    {
                        label:'Other',
                        value:'Other'
                    }
                ],
                service:[
                    {
                        label:'Waitlist',
                        value:'Waitlist'
                    },
                    {
                        label:'Reservation',
                        value:'Reservation'
                    },
                    {
                        label:'Scan Table',
                        value:'Scan Table'
                    },
                    {
                        label: 'Emenu',
                        value: 'Emenu'
                    },
                    {
                        label:'Online Ordering',
                        value:'Online Ordering'
                    },
                    {
                        label:'Survey',
                        value:'Survey'
                    },
                    {
                        label:'Pass',
                        value:'Pass'
                    },
                    {
                        label:'TeamUp',
                        value:'TeamUp'
                    },
                    {
                        label:'Website',
                        value:'Website'
                    },
                    {
                        label:'All-in-one',
                        value:'All-in-one'
                    }
                ],
                contract_type:[
                    {
                        label:'Monthly',
                        value:'Monthly'
                    },
                    {
                        label:'Half year',
                        value:'Half year'
                    },
                    {
                        label:'Yearly',
                        value:'Yearly'
                    }
                ],
                business_status:[
                    {
                        label:'没有上线-刚刚签约(初始状态)',
                        value:'0'
                    },
                    {
                        label:'没有上线-准备物料和其他',
                        value:'1'
                    },
                    {
                        label:'没有上线-安装(物料以及其他已准备好)',
                        value:'2'
                    },
                    {
                        label:'已上线-试用期中',
                        value:'3'
                    },
                    {
                        label:'已上线-合同签订中',
                        value:'4'
                    },
                    {
                        label:'已上线-invoice已发，未付款',
                        value:'5'
                    },
                    {
                        label:'已上线-开始付款',
                        value:'6'
                    },
                    {
                        label:'已上线-未付款(等需求完成)',
                        value:'7'
                    },
                    {
                        label:'已上线-未付款(overdue)',
                        value:'8'
                    },
                    {
                        label:'已上线-未付款（现在暂时没有功能可以用）',
                        value:'9'
                    },
                    {
                        label:'取消服务',
                        value:'10'
                    }
                ],
                online_status:[
                    {
                        label:'待定',
                        value:'0',
                    },
                    {
                        label:'物料准备中',
                        value:'1',
                    },
                    {
                        label:'等待安装',
                        value:'2',
                    },
                    {
                        label:'等待需求',
                        value:'3',
                    },
                    {
                        label:'已安装',
                        value:'4',
                    },
                    {
                        label:'已上线',
                        value:'5',
                    },
                    {
                        label:'沉睡中',
                        value:'6',
                    },
                    {
                        label: '废弃账号',
                        value:'7'
                    }
                ],
                contract_status:[
                    {
                        label:'待定',
                        value:'0',
                    },
                    {
                        label:'达成口头协议',
                        value:'1',
                    },
                    {
                        label:'签约中',
                        value:'2',
                    },
                    {
                        label:'已签合同',
                        value:'3',
                    },
                    {
                        label:'合同期内',
                        value:'4',
                    },
                    {
                        label:'合同暂停',
                        value:'5',
                    },
                    {
                        label:'合同即将过期',
                        value:'6',
                    },
                    {
                        label:'合同已过期',
                        value:'7',
                    }
                ],
                payment_status:[
                    {
                        label:'试用期',
                        value:'0',
                    },
                    {
                        label:'未付款',
                        value:'1',
                    },
                    {
                        label: 'invoice已发未付款',
                        value: '5'
                    },
                    {
                        label:'已付款',
                        value:'2',
                    },
                    {
                        label:'Overdue',
                        value:'3',
                    },
                    {
                        label:'已有支付方式',
                        value:'4'
                    }
                ]
            }
        },
        mounted() {
            this.is_loading = true;
            this.initData({
                pageNo:1,
                pageSize:50,
                ...this.searchList,
                cb: this.set_loading_cb
            });
            this.unid(this.columns,this.searchItems)
            this.handleSynchronStore();//同步数据
        },
        computed: {
            ...mapGetters({
                // loading: 'app/getLoading',
                content: 'app/getConetent'
            }),
            pagination(){
                return {
                    total:this.content.total || 0,
                    pageSize:this.content.pageSize || 50,
                    current:this.content.pageNo || 1
                }
            },
        },
        methods: {
            goToNewPage(){
                this.$router.push({ path: '/business' });
            },
            set_loading_cb() {
                this.is_loading = false;
            },
            // 接口
            ...mapActions({
                initData:'app/initData',  //搜索接口
                updateTable:'app/updateTable',  //更新数据接口
                synchronStore:'app/synchronStore'  //同步商户信息接口
            }),
            // 同步商户信息
            handleSynchronStore(){
                this.synchronStore({})
            },
            // 搜索
            handleTableSearch(){
                let obj = {...this.searchList};
                let _obj = {
                    contract_time:this.formatDateRange(obj.contract_time),
                    contract_stime:this.formatDateRange(obj.contract_stime),
                    contract_etime:this.formatDateRange(obj.contract_etime),
                    free_trail_start_time:this.formatDateRange(obj.free_trail_start_time),
                    free_trail_end_time:this.formatDateRange(obj.free_trail_end_time),
                    official_charging_time:this.formatDateRange(obj.official_charging_time),
                }
                let params = {...obj,..._obj};
                this.is_loading = true;

                this.initData({
                    pageNo:1,
                    pageSize:50,
                    ...params,
                    cb: this.set_loading_cb
                })
            },
            // 重置搜索条件
            handleTableReset(){
                let obj = {...this.searchList}
                for (const key in obj) {
                    if(key == 'contract_time' || key == 'free_trail_start_time' || key == 'free_trail_end_time' || key == 'official_charging_time'){
                        obj[key] = []
                    }else{
                        obj[key] = ""
                    }
                }
                this.searchList = obj
            },
            // 点击分页
            handleTableChange(pagination){
                this.is_loading = true;
                this.initData({
                    pageNo:pagination.current,
                    pageSize:pagination.pageSize,
                    ...this.searchList,
                    cb: this.set_loading_cb
                })
            },
            // 打开编辑弹窗
            handleSetting(record){
                this.setObj = {...record};
                this.setObj.resetaurant_type = this.setObj.resetaurant_type?this.setObj.resetaurant_type.split(','):[];
                this.setObj.service = this.setObj.service?this.setObj.service.split(','):[];
                this.visible = !this.visible;
            },
            // 总计
            expensesAll(record){
                return Number(record.equipment_expenses_charge) + Number(record.material_expenses_charge) + Number(record.other_expenses_charge)
            },
            //商户上线状态
            businessStatus(record){
                let business_status = record.business_status;
                if(business_status === ''){
                    return ''
                }
                for (let index = 0; index < this.business_status.length; index++) {
                    if(this.business_status[index].value == business_status){
                        return this.business_status[index].label
                    }
                }
                return ''
            },
            //上线状态
            onlineStatus(record){
                let online_status = record.online_status;
                if(online_status === ''){
                    return ''
                }
                for (let index = 0; index < this.online_status.length; index++) {
                    if(this.online_status[index].value == online_status){
                        return this.online_status[index].label
                    }
                }
                return ''
            },
            //合同状态
            contractStatus(record){
                let contract_status = record.contract_status;
                if(contract_status === ''){
                    return ''
                }
                for (let index = 0; index < this.contract_status.length; index++) {
                    if(this.contract_status[index].value == contract_status){
                        return this.contract_status[index].label
                    }
                }
                return ''
            },
            //付款状态
            paymentStatus(record){
                let payment_status = record.payment_status;
                if(payment_status === ''){
                    return ''
                }
                for (let index = 0; index < this.payment_status.length; index++) {
                    if(this.payment_status[index].value == payment_status){
                        return this.payment_status[index].label
                    }
                }
            },
            // 弹窗提交
            handleSubmit(){
                let obj = {...this.setObj};
                if(obj.remark.length>1000){
                    this.$message.warning('Remark maxLength 1000!');
                    return
                }
                let _obj = {
                    contract_time:this.formatDate(obj.contract_time),
                    contract_stime:this.formatDate(obj.contract_stime),
                    contract_etime:this.formatDate(obj.contract_etime),
                    free_trail_start_time:this.formatDate(obj.free_trail_start_time),
                    free_trail_end_time:this.formatDate(obj.free_trail_end_time),
                    official_charging_time:this.formatDate(obj.official_charging_time),
                }
                let params = {...obj,..._obj}
                this.updateTable({
                    ...params,
                    afterdata:{
                        pageNo:this.pagination.current,
                        pageSize:this.pagination.pageSize,
                        ...this.searchList
                    }
                })
                this.onClose()
            },
            // 弹窗关闭
            onClose() {
                this.visible = false;
            },
            // 多维数组children的摊平
            unid(arr,result){
                for(let item of arr){
                    if(Object.prototype.hasOwnProperty.call(item,'children')){
                        this.unid(item.children,result);
                    }else{
                        result.push(item);
                    }
                }
                return result;
            },
            // 格式化时间
            formatDate(date){
                if(!date){
                    return ''
                }
                let _date = new Date(date);
                let y = _date.getFullYear();
                let m = _date.getMonth() + 1;
                let d = _date.getDate();
                return y + '-' + m + '-' + d;
            },
            // 格式化时间日期范围
            formatDateRange(date){
                if(!date.length){
                    return ''
                }
                let first = date[0];
                let last = date[1];
                let _first = new Date(first);
                let _last = new Date(last);
                let _firstM = _first.getMonth() + 1 > 9 ? _first.getMonth() + 1 : '0' + (_first.getMonth() + 1)
                let _lastM = _last.getMonth() + 1 > 9 ? _last.getMonth() + 1 : '0' + (_last.getMonth() + 1)
                let _firstD = _first.getDate() > 9 ? _first.getDate() : '0' + _first.getDate()
                let _lastD = _last.getDate() > 9 ? _last.getDate() : '0' + _last.getDate()
                return [_first.getFullYear() + '-' + _firstM + '-' + _firstD,_last.getFullYear() + '-' +  _lastM + '-' + _lastD]
            }
        },
        watch:{
            flag(val){
                if(val){
                    this.is_loading = true;
                    
                    this.initData({
                        pageNo:this.pagination.current,
                        pageSize:this.pagination.pageSize,
                        ...this.searchList,
                        cb: this.set_loading_cb
                    })
                    this.onClose();
                    this.flag = false
                }
            }
        }
    }
</script>
