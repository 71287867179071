<template lang="html">
    <div>
        <div id="app">
            <router-view></router-view>
        </div>
    </div>
</template>
<style>
</style>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

    export default {
        name: 'app',
        data() {
            return {
                is_login: this.$router.history.current.path.indexOf('/login') > -1 ? true : false,
            };
        },
        beforeMount() {
            this.is_login = this.$router.history.current.path.indexOf('/login') > -1 ? true : false;

            let _token = window.localStorage.getItem('token') || '';

            if (!this.is_login && !_token) {
                this.$router.replace({ path: "login" });
            }
        },
        updated() {
        },
        beforeCreate() {
        },
        created() {
        },
        watch: {
            msg_opts() {
                console.log(this.msg_opts);
                if (this.msg_opts) {
                    this.$message.warning(this.msg_opts.msg);
                }
            }
        },
        computed: {
            ...mapGetters({
                msg_opts: 'app/get_app_msg',
                show_loading: 'app/get_loading'
            })
        },
        methods: {
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG'
            })
        }
    };
</script>
