<template>
  <div class="store-list">
    <!-- 搜索区域 -->
    <div class="search-box" :class="{ down: down }">
      <div class="search-items">
        <span class="label">商户名:</span>
        <a-input v-model="searchList['sname']"></a-input>
      </div>
      <div class="search-items">
        <span class="label">Email:</span>
        <a-input v-model="searchList['email']"></a-input>
      </div>
      <div class="search-items">
        <span class="label">商户状态:</span>
        <a-select style="width: 100%" v-model="searchList['business_status']">
          <a-select-option v-for="its in business_status" :key="its.value">
            {{ its.label }}
          </a-select-option>
        </a-select>
      </div>
      <div class="search-items">
        <span class="label">付款状态:</span>
        <a-select style="width: 100%" v-model="searchList['payment_status']">
          <a-select-option v-for="its in business_status" :key="its.value">
            {{ its.label }}
          </a-select-option>
        </a-select>
      </div>
      <div class="search-items">
        <span class="label">新/续约:</span>
        <a-select
          style="width: 100%"
          v-model="searchList['is_renewal_business']"
        >
          <a-select-option value="0">新商户</a-select-option>
          <a-select-option value="1">续约商户</a-select-option>
        </a-select>
      </div>
      <div class="search-items">
        <span class="label">时间范围:</span>
        <a-range-picker
          style="width: 100%"
          v-model="searchList['time_range']"
        />
      </div>
      <div class="search-items search-btn" :class="{ down: down }">
        <a-button type="link" v-if="!down" @click="down = !down">
          <span>展开</span>
          <a-icon type="down" />
        </a-button>
        <a-button type="link" v-if="down" @click="down = !down">
          <span>收起</span>
          <a-icon type="up" />
        </a-button>
        <a-button type="primary" @click="handleTableSearch">Search</a-button>
        <a-button type="default" @click="handleTableReset">Reset</a-button>
      </div>
    </div>
    <div class="btn-box">
      <div>
        <a-button type="primary" @click="handleSynchronStore"
          >同步商户信息</a-button
        >
        <a-button style="margin-left: 20px" type="info" @click="goToNewPage">{{
          new Date().getFullYear() + "年（ARR表）"
        }}</a-button>
      </div>
      <div v-if="searchStatus">
        <a-button type="primary">商户数量: {{ content.total }}</a-button>
        <a-button style="margin-left: 20px" type="primary"
          >总金额: {{ content.totalAmount }}</a-button
        >
      </div>
    </div>
    <!-- 表格区域 -->
    <div class="table-box">
      <a-table
        :columns="searchStatus ? searchColumns : columns"
        :data-source="content.data"
        :pagination="pagination"
        :rowKey="(record) => record.id"
        bordered
        size="middle"
        @change="handleTableChange"
        :scroll="{ x: 1000, y: 435 }"
      >
        <template slot="create_time" slot-scope="text">
          <span v-if="text != ''">{{ formatDateAmerica(text) }}</span>
        </template>
        <template slot="sname" slot-scope="text, record">
          <a-badge
            :count="record.duty_count"
            :number-style="
              record.duty_count == 0
                ? { backgroundColor: '#108ee9' }
                : { backgroundColor: 'red' }
            "
            :dot="false"
            show-zero
          >
            <a-button type="link" @click="() => handleDutySetting(record)">{{
              record.sname
            }}</a-button>
          </a-badge>
        </template>
        <template slot="contract_status" slot-scope="text, record">
          <a-select
            style="width: 100%"
            v-model="record.contract_status"
            :disabled="searchStatus"
            @change="
              (key) => handelUpdateStatus(key, record.sid, 'contract_status')
            "
          >
            <a-select-option v-for="its in contract_status" :key="its.value">
              {{ its.label }}
            </a-select-option>
          </a-select>
        </template>
        <template slot="payment_status" slot-scope="text, record">
          <a-select
            style="width: 100%"
            v-model="record.payment_status"
            :disabled="searchStatus"
            @change="
              (key) => handelUpdateStatus(key, record.sid, 'payment_status')
            "
          >
            <a-select-option v-for="its in payment_status" :key="its.value">
              {{ its.label }}
            </a-select-option>
          </a-select>
        </template>
        <template slot="business_status" slot-scope="text, record">
          <a-select
            style="width: 100%"
            v-model="record.business_status"
            :disabled="searchStatus"
            @change="
              (key) => handelUpdateStatus(key, record.sid, 'business_status')
            "
          >
            <a-select-option v-for="its in business_status" :key="its.value">
              {{ its.label }}
            </a-select-option>
          </a-select>
        </template>
        <template slot="service" slot-scope="text, record">
          <a-select
            style="width: 100%"
            mode="multiple"
            :default-value="text != '' ? text.split(',') : undefined"
            @blur="(key) => handelUpdateService(key, record.sid)"
          >
            <template slot="removeIcon"
              ><a-icon type="right" style="font-size: 0"
            /></template>
            <a-select-option v-for="its in service" :key="its.value">
              {{ its.label }}
            </a-select-option>
          </a-select>
        </template>
        <template slot="gm_name" slot-scope="text, record">
          <a-button
            style="width: 100%"
            type="link"
            @click="() => handleSetting(record)"
            >{{
              record.position + (text != "" ? ": " : "") + record.gm_name
            }}</a-button
          >
        </template>
        <template slot="resetaurant_type" slot-scope="text, record">
          <div v-if="record.resetaurant_type.split(',').length > 1">
            <div
              v-for="(item, i) in record.resetaurant_type.split(',')"
              :key="i"
            >
              <span>{{ item }}</span>
              <a-divider style="margin: 10px 0" />
            </div>
          </div>
          <div v-else>{{ record.resetaurant_type }}</div>
        </template>
        <template slot="sales_manager" slot-scope="text, record">
          <a-button
            style="width: 100%"
            type="link"
            @click="() => handleSetting(record, 'contract')"
            >{{ record.sales_manager }}</a-button
          >
        </template>
        <template slot="free_trail_start_time" slot-scope="text">
          <span v-if="text != ''">{{ formatDateAmerica(text) }}</span>
        </template>
        <template slot="free_trail_end_time" slot-scope="text">
          <span v-if="text != ''">{{ formatDateAmerica(text) }}</span>
        </template>
        <template slot="contract_stime" slot-scope="text">
          <span v-if="text != ''">{{ formatDateAmerica(text) }}</span>
        </template>
        <template slot="contract_etime" slot-scope="text">
          <span v-if="text != ''">{{ formatDateAmerica(text) }}</span>
        </template>
        <template slot="shoud_charge" slot-scope="text, record">
          <a-button
            :ref="record.id"
            style="width: 100%"
            type="link"
            @click="() => handelBill(record, 0)"
            >{{ chargeColor(record) }}</a-button
          >
        </template>
        <template slot="check" slot-scope="text, record">
          <a-button
            style="width: 100%"
            type="link"
            @click="() => billList(record.sid)"
            >查看</a-button
          >
        </template>
        <template slot="remark" slot-scope="text, record">
          <a-button
            style="width: 100%; height: 100%"
            type="link"
            @click="() => handleremarkSetting(record)"
          >
            <span v-html="remark(record.business_level, 'level')"></span>
            <span
              style="margin: 0 10px"
              v-html="remark(record.business_type, 'type')"
            ></span>
            <span v-html="remark(record.business_tag, 'tag')"></span><br />
            {{ text === "" ? "请添加内容" : text }}
          </a-button>
        </template>
        <template slot="is_renewal_business" slot-scope="text, record">
          <a-button :disabled="searchStatus">{{
            record.is_renewal_business == 0 ? "新商户" : "续约商户"
          }}</a-button>
        </template>
      </a-table>
    </div>
    <a-drawer
      title="Setting"
      placement="right"
      :visible="visible"
      :body-style="{ paddingBottom: '80px' }"
      @close="onClose"
      :width="600"
    >
      <div class="search">
        <div v-for="(item, index) in searchItems" :key="index">
          <template v-if="item.hasOwnProperty('type')">
            <div
              v-if="
                item.type == 'input' &&
                !item.hidden &&
                item.option == (isContract ? 'contract' : undefined)
              "
              class="search-items"
            >
              <span class="label" style="min-width: 228px" :title="item.title"
                >{{ item.title }}:</span
              >
              <a-input
                :disabled="item.disabled"
                v-model="setObj[item.dataIndex]"
              ></a-input>
            </div>
            <div
              v-if="
                item.type == 'select' &&
                item.option == (isContract ? 'contract' : undefined)
              "
              class="search-items"
            >
              <span class="label" style="min-width: 228px" :title="item.title"
                >{{ item.title }}:</span
              >
              <a-select
                v-if="item.dataIndex == 'position'"
                style="width: 100%"
                v-model="setObj[item.dataIndex]"
              >
                <a-select-option v-for="its in position" :key="its.value">
                  {{ its.label }}
                </a-select-option>
              </a-select>
              <a-select
                v-else-if="item.dataIndex == 'resetaurant_type'"
                mode="multiple"
                style="width: 100%"
                v-model="setObj[item.dataIndex]"
              >
                <template v-for="its in resetaurant_type">
                  <template v-if="its.children && its.children.length">
                    <a-select-opt-group :label="its.label" :key="its.value">
                      <a-select-option
                        v-for="it in its.children"
                        :key="it.value"
                      >
                        {{ it.label }}
                      </a-select-option>
                    </a-select-opt-group>
                  </template>
                  <template v-else>
                    <a-select-option :key="its.value">
                      {{ its.label }}
                    </a-select-option>
                  </template>
                </template>
              </a-select>
              <a-select
                v-else-if="item.dataIndex == 'ethnicity'"
                style="width: 100%"
                v-model="setObj[item.dataIndex]"
              >
                <a-select-option v-for="its in ethnicity" :key="its.value">
                  {{ its.label }}
                </a-select-option>
              </a-select>
              <a-select
                v-else-if="item.dataIndex == 'sales_manager'"
                style="width: 100%"
                v-model="setObj[item.dataIndex]"
              >
                <a-select-option v-for="its in sales_manager" :key="its.value">
                  {{ its.label }}
                </a-select-option>
              </a-select>
              <a-select
                v-else-if="item.dataIndex == 'contract_type'"
                style="width: 100%"
                v-model="setObj[item.dataIndex]"
              >
                <a-select-option v-for="its in contract_type" :key="its.value">
                  {{ its.label }}
                </a-select-option>
              </a-select>
            </div>
            <div
              v-if="
                item.type == 'inputNumber' &&
                item.option == (isContract ? 'contract' : undefined)
              "
              class="search-items"
            >
              <span class="label" style="min-width: 228px" :title="item.title"
                >{{ item.title }}:</span
              >
              <a-input
                v-if="item.dataIndex == 'contract_price'"
                :disabled="item.disabled"
                v-model="contractPrice"
                >{{ contractPrice }}</a-input
              >
            </div>
            <div
              v-if="
                item.type == 'date' &&
                item.option == (isContract ? 'contract' : undefined)
              "
              class="search-items"
            >
              <span class="label" style="min-width: 228px" :title="item.title"
                >{{ item.title }}:</span
              >
              <a-date-picker
                style="width: 240px"
                v-model="setObj[item.dataIndex]"
              />
            </div>
          </template>
        </div>
      </div>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="onClose">
          Cancel
        </a-button>
        <a-button type="primary" @click="handleSubmit"> Submit </a-button>
      </div>
    </a-drawer>
    <a-drawer
      title="Duty Setting"
      placement="left"
      :visible="dutyListVisible"
      :body-style="{ paddingBottom: '80px' }"
      @close="onDutyClose"
      :width="800"
    >
      <div class="search">
        <a-card style="width: 100%" :title="setDutyObj.sname">
          <span slot="extra">{{ setDutyObj.sales_manager }}</span>
          <a-button
            style="margin-bottom: 15px"
            v-if="!isMore"
            block
            @click="isMore = !isMore"
          >
            请输入新内容
          </a-button>
          <a-tabs type="card" @change="onTabChange" v-model="active_tab_key">
            <a-tab-pane
              v-for="t in tabList"
              :key="t.key"
              :tab="t.tab + '(' + statusCount['status' + t.key] + ')'"
            >
              <a-descriptions
                v-for="item in dutyContent.data"
                :key="item.id"
                style="margin: 10px 0"
                size="small"
                title=""
                bordered
              >
                <a-descriptions-item label="创建人">
                  {{ item.publish_user }}
                </a-descriptions-item>
                <a-descriptions-item label="创建时间" :span="2">
                  {{ formatDatetoAmerica(item.create_time) }}
                </a-descriptions-item>
                <a-descriptions-item label="完成人" v-if="t.key === '1'">
                  {{ item.complete_user }}
                </a-descriptions-item>
                <a-descriptions-item
                  label="完成时间"
                  v-if="t.key === '1'"
                  :span="2"
                >
                  {{ formatDatetoAmerica(item.complete_time) }}
                </a-descriptions-item>
                <a-descriptions-item label="任务等级">
                  <a-icon
                    type="star"
                    theme="twoTone"
                    :style="{ fontSize: '24px', margin: '0 6px' }"
                  />
                  <a-icon
                    v-if="item.duty_level > 1"
                    type="star"
                    theme="twoTone"
                    :style="{ fontSize: '24px', margin: '0 6px' }"
                  />
                  <a-icon
                    v-if="item.duty_level > 2"
                    type="star"
                    theme="twoTone"
                    :style="{ fontSize: '24px', margin: '0 6px' }"
                  />
                </a-descriptions-item>
                <a-descriptions-item label="任务分类" :span="2">
                  {{ item.duty_type }}
                </a-descriptions-item>
                <a-descriptions-item label="任务详情" :span="3">
                  {{ item.duty_content }}
                </a-descriptions-item>
                <a-descriptions-item label="任务状态">
                  <a-select
                    :default-value="item.status === 0 ? '正在处理' : '已完成'"
                    style="width: 120px"
                    @change="(key) => handelUpdateDutyStatus(key, item.id)"
                  >
                    <a-select-option value="0">正在处理</a-select-option>
                    <a-select-option value="1">已完成</a-select-option>
                  </a-select>
                </a-descriptions-item>
                <a-descriptions-item label="操作" :span="1">
                  <a-button @click="handelUpdateDuty(item)"> 修改 </a-button>
                </a-descriptions-item>
              </a-descriptions>
            </a-tab-pane>
          </a-tabs>
        </a-card>
        <a-pagination
          v-model="dutyPagination.pageNo"
          :total="dutyPagination.total"
          @change="handelDutyTableChange"
        />
        <a-modal :visible="isMore" :width="600" destroyOnClose>
          <div class="search-items">
            <span class="label">任务等级:</span>
            <a-icon
              ref="star1"
              type="star"
              :theme="dutyObj.duty_level >= 1 ? 'twoTone' : 'outlined'"
              :style="{ fontSize: '24px', margin: '0 6px' }"
              @click="clickStar(1)"
            />
            <a-icon
              ref="star2"
              type="star"
              :theme="dutyObj.duty_level >= 2 ? 'twoTone' : 'outlined'"
              :style="{ fontSize: '24px', margin: '0 6px' }"
              @click="clickStar(2)"
            />
            <a-icon
              ref="star3"
              type="star"
              :theme="dutyObj.duty_level === 3 ? 'twoTone' : 'outlined'"
              :style="{ fontSize: '24px', margin: '0 6px' }"
              @click="clickStar(3)"
            />
          </div>
          <div class="search-items">
            <span class="label">任务类型:</span>
            <a-select
              style="width: 80%"
              v-model="dutyObj.duty_type"
              placeholder="please select duty type"
            >
              <template v-for="its in duty_type">
                <template v-if="its.children && its.children.length">
                  <a-select-opt-group :label="its.label" :key="its.value">
                    <a-select-option v-for="it in its.children" :key="it.value">
                      {{ it.label }}
                    </a-select-option>
                  </a-select-opt-group>
                </template>
                <template v-else>
                  <a-select-option :key="its.value">
                    {{ its.label }}
                  </a-select-option>
                </template>
              </template>
            </a-select>
          </div>
          <div class="search-items">
            <span class="label">任务内容:</span>
            <a-textarea
              v-model="dutyObj.duty_content"
              placeholder="please input duty content"
              :auto-size="{ minRows: 4 }"
            />
          </div>
          <template slot="footer">
            <a-button key="back" @click="onDutyCancel"> Cancel </a-button>
            <a-button
              key="submit"
              type="primary"
              @click="handleDutySubmit"
              :disabled="!dutyObj.duty_level || !dutyObj.duty_type"
            >
              Submit
            </a-button>
          </template>
        </a-modal>
      </div>
      <div class="loading_layer" v-if="is_duty_loading">
        <a-spin />
      </div>
    </a-drawer>
    <a-modal
      :width="600"
      v-model="remarkVisible"
      title="修改备注项"
      @ok="handleRemarkOk"
    >
      <div class="search-items">
        <span class="label">商户等级:</span>
        <a-select
          style="width: 80%"
          v-model="setObj.business_level"
          placeholder="please select business level"
        >
          <template v-for="its in business_level">
            <a-select-option :key="its.value">
              {{ its.label }}
            </a-select-option>
          </template>
        </a-select>
      </div>
      <div class="search-items">
        <span class="label">商户类型:</span>
        <a-select
          style="width: 80%"
          v-model="setObj.business_type"
          placeholder="please select business type"
        >
          <template v-for="its in business_type">
            <a-select-option :key="its.value">
              {{ its.label }}
            </a-select-option>
          </template>
        </a-select>
      </div>
      <div class="search-items">
        <span class="label">商户标签:</span>
        <a-select
          style="width: 80%"
          v-model="setObj.business_tag"
          placeholder="please select business tag"
        >
          <template v-for="its in business_tag">
            <a-select-option :key="its.value">
              {{ its.label }}
            </a-select-option>
          </template>
        </a-select>
      </div>
      <div class="search-items">
        <span class="label">描述内容:</span>
        <a-textarea
          v-model="setObj.remark"
          placeholder="please input remark"
          :auto-size="{ minRows: 4 }"
        />
      </div>
    </a-modal>
    <a-modal
      :width="800"
      v-model="billListVisible"
      title="历史收款"
      :footer="null"
    >
      <a-table
        :columns="billColumns"
        :data-source="billContent.data"
        :rowKey="(record) => record.id"
        bordered
        size="middle"
        width="100%"
        :scroll="{ y: 435 }"
      >
        <template slot="create_time" slot-scope="text">
          <span v-if="text != ''">{{ formatDateAmerica(text) }}</span>
        </template>
        <template slot="complete_time" slot-scope="text">
          <span v-if="text != ''">{{ formatDateAmerica(text) }}</span>
        </template>
        <template slot="operation" slot-scope="text, record">
          <a-button type="link" @click="() => handelBill(record, 1)"
            >查看</a-button
          >
        </template>
      </a-table>
    </a-modal>
    <a-modal
      :width="600"
      v-model="billVisible"
      :title="
        billObj.status == 1
          ? '已付款收据' + formatDateAmerica(billObj.complete_time)
          : '未付款收据'
      "
    >
      <template v-if="myChargeList.length > 0">
        <div
          class="search-items"
          v-for="(item, index) in myChargeList"
          :key="index"
        >
          <a-input
            style="width: 150px"
            v-model="item.name"
            placeholder="请输入应收款项"
            >{{ item.name }}</a-input
          >
          <a-input
            style="width: 150px"
            v-model="item.amount"
            prefix="$"
            placeholder="请输入应收额"
            @change="(e) => amountChange(e, 'amount', item)"
            >${{ item.amount }}</a-input
          >
          <a-checkbox
            v-model="item.apply_tax"
            @change="(e) => amountChange(e, 'tax', item)"
          >
            Apply Tax
          </a-checkbox>
          <a-icon
            v-if="billObj.status === 0 && myChargeList.length > 0"
            type="minus-circle-o"
            @click="removeDomain(item)"
          />
        </div>
      </template>
      <a-button
        v-if="!billObj.status || billObj.status == 0"
        type="dashed"
        style="width: 30%"
        @click="addDomain"
      >
        <a-icon type="plus" /> 添加更多
      </a-button>
      <a-divider />
      <div class="search-items">
        <a-button style="width: 150px" type="primary">NY tax(8.875%)</a-button>
        <a-input
          style="width: 150px"
          v-model="billObj.tip"
          prefix="$"
          placeholder="应缴税额"
          >${{ billObj.tip }}</a-input
        >
        <span style="width: 95px"></span>
      </div>
      <div class="search-items">
        <span style="width: 150px"></span>
        <a-input
          style="width: 150px"
          v-model="billObj.total_amount"
          prefix="$"
          placeholder="总金额"
        >
          ${{
            billObj.status == 0 ? billObj.total_amount : billObj.real_amount
          }}
        </a-input>
        <span style="width: 95px"></span>
      </div>
      <template slot="footer">
        <a-button key="back" @click="billVisible = !billVisible">
          取消
        </a-button>
        <template v-if="billObj.status === 1">
          <a-button type="danger" @click="() => handleBillStatus(0)">
            切为未付款
          </a-button>
          <a-button type="primary">
            {{ billObj.charge_platform }}
          </a-button>
        </template>
        <template v-else>
          <a-button
            key="submit"
            type="primary"
            @click="() => handleBillSubmit(billObj.id)"
          >
            保存
          </a-button>
          <a-button
            v-if="billObj.id"
            type="danger"
            @click="() => handledeleteBill(billObj.id, billObj.sid)"
          >
            删除
          </a-button>
          <a-popconfirm
            ok-text="STRIPE"
            cancel-text="QUICKBOOK"
            title="请选择付款平台"
            @confirm="handleBillStatus(1, 'STRIPE')"
            @cancel="handleBillStatus(1, 'QUICKBOOK')"
          >
            <a-button v-if="billObj.id" type="primary"> 已付款 </a-button>
          </a-popconfirm>
        </template>
      </template>
    </a-modal>
    <div class="loading_layer" v-if="is_loading">
      <a-spin />
    </div>
  </div>
</template>
<style scoped>
.loading_layer {
  text-align: center;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0%;
  left: 0%;
  line-height: 500px;
  background: #fff;
  z-index: 100;
  opacity: 0.5;
}
.store-list {
  padding: 20px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f5f5f5;
}
.search-box {
  height: 72px;
  overflow: hidden;
  border-radius: 5px;
  padding: 20px;
  background: #fff;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  padding-right: 200px;
  position: relative;
}
.search-box.down {
  height: auto;
  padding-right: 20px;
  padding-bottom: 52px;
}
.search-items {
  margin-right: 10px;
  width: 500px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-around;
}
.search-btn {
  position: absolute;
  right: 0;
  bottom: 50%;
  margin-bottom: -16px;
  display: flex;
  justify-content: flex-end;
}
.search-btn.down {
  bottom: 0;
  margin-bottom: 20px;
}
.search-btn .ant-btn {
  margin: 0 10px;
}
.label {
  min-width: 100px;
  text-align: right;
  padding-right: 10px;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}
.table-box {
  padding: 20px;
  background: #fff;
  border-radius: 5px;
}
.btn-box {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 0 20px;
  background: #fff;
}
.redColor {
  color: red;
}
</style>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
const moment = require("moment-timezone");
import { message } from "ant-design-vue";

export default {
  name: "business",
  data() {
    return {
      down: false,
      searchStatus: false,
      is_loading: false,
      is_duty_loading: false,
      visible: false,
      dutyListVisible: false,
      isMore: false,
      isContract: false,
      remarkVisible: false,
      billListVisible: false,
      billVisible: false,
      billIsSubmit: false,
      myChargeList: [],
      billObj: {
        charge_list: [],
      },
      dutyObj: {
        duty_level: undefined,
        duty_type: undefined,
        duty_content: undefined,
      },
      setObj: {},
      setDutyObj: {},
      active_tab_key: "0",
      searchList: {
        sname: undefined,
        email: undefined,
        business_status: undefined,
        payment_status: undefined,
        is_renewal_business: undefined,
        time_range: undefined,
      },
      searchItems: [],
      columns: [
        {
          title: "#",
          dataIndex: "rank",
          width: 50,
          fixed: "left",
        },
        {
          title: "创建日期",
          dataIndex: "create_time",
          key: "create_time",
          width: 150,
          ellipsis: true,
          fixed: "left",
          scopedSlots: { customRender: "create_time" },
        },
        {
          title: "商户名",
          dataIndex: "sname",
          key: "sname",
          width: 250,
          ellipsis: true,
          disabled: true,
          fixed: "left",
          scopedSlots: { customRender: "sname" },
        },
        {
          title: "商户基本信息",
          children: [
            {
              title: "登录信息",
              dataIndex: "user_name",
              key: "user_name",
              width: 200,
              ellipsis: true,
              disabled: true,
              type: "input",
            },
            {
              title: "商户状态",
              dataIndex: "business_status",
              key: "business_status",
              width: 150,
              ellipsis: true,
              scopedSlots: { customRender: "business_status" },
            },
            {
              title: "合同状态",
              dataIndex: "contract_status",
              key: "contract_status",
              width: 200,
              ellipsis: true,
              scopedSlots: { customRender: "contract_status" },
            },
            {
              title: "付款状态",
              dataIndex: "payment_status",
              key: "payment_status",
              width: 200,
              ellipsis: true,
              scopedSlots: { customRender: "payment_status" },
            },
            {
              title: "商户联系人",
              dataIndex: "gm_name",
              key: "gm_name",
              width: 200,
              ellipsis: true,
              scopedSlots: { customRender: "gm_name" },
              type: "input",
            },
            {
              title: "联系人电话",
              dataIndex: "phone_num",
              key: "phone_num",
              width: 200,
              ellipsis: true,
              type: "input",
            },
            {
              title: "微信",
              dataIndex: "wechat",
              key: "wechat",
              width: 200,
              ellipsis: true,
              type: "input",
            },
            {
              title: "Email",
              dataIndex: "email",
              key: "email",
              width: 200,
              ellipsis: true,
              type: "input",
            },
            {
              title: "地址",
              dataIndex: "state",
              key: "state",
              width: 200,
              ellipsis: true,
              type: "input",
            },
            {
              title: "职位",
              dataIndex: "position",
              key: "position",
              width: 0,
              ellipsis: true,
              type: "select",
            },
            {
              title: "餐厅类型",
              dataIndex: "resetaurant_type",
              key: "resetaurant_type",
              width: 150,
              ellipsis: true,
              align: "center",
              scopedSlots: { customRender: "resetaurant_type" },
              type: "select",
            },
            {
              title: "国籍",
              dataIndex: "ethnicity",
              key: "ethnicity",
              width: 100,
              ellipsis: true,
              type: "select",
            },
            {
              title: "服务",
              dataIndex: "service",
              key: "service",
              width: 180,
              ellipsis: true,
              scopedSlots: { customRender: "service" },
            },
          ],
        },
        {
          title: "商户合约信息",
          children: [
            {
              title: "销售负责人",
              dataIndex: "sales_manager",
              key: "sales_manager",
              width: 100,
              ellipsis: true,
              type: "select",
              scopedSlots: { customRender: "sales_manager" },
              option: "contract",
            },
            {
              title: "合约类型",
              dataIndex: "contract_type",
              key: "contract_type",
              width: 150,
              ellipsis: true,
              type: "select",
              option: "contract",
            },
            {
              title: "合约价格",
              dataIndex: "contract_price",
              key: "contract_price",
              width: 150,
              ellipsis: true,
              disabled: true,
              type: "inputNumber",
              option: "contract",
            },
            {
              title: "试用期开始",
              dataIndex: "free_trail_start_time",
              key: "free_trail_start_time",
              width: 150,
              ellipsis: true,
              type: "date",
              scopedSlots: { customRender: "free_trail_start_time" },
              option: "contract",
            },
            {
              title: "试用期结束",
              dataIndex: "free_trail_end_time",
              key: "free_trail_end_time",
              width: 150,
              ellipsis: true,
              type: "date",
              scopedSlots: { customRender: "free_trail_end_time" },
              option: "contract",
            },
            {
              title: "合约生效",
              dataIndex: "contract_stime",
              key: "contract_stime",
              width: 150,
              ellipsis: true,
              type: "date",
              scopedSlots: { customRender: "contract_stime" },
              option: "contract",
            },
            {
              title: "合约结束",
              dataIndex: "contract_etime",
              key: "contract_etime",
              width: 150,
              ellipsis: true,
              type: "date",
              scopedSlots: { customRender: "contract_etime" },
              option: "contract",
            },
            {
              title: "签约赠送月",
              dataIndex: "give_month",
              key: "give_month",
              width: 150,
              ellipsis: true,
              type: "input",
              option: "contract",
            },
          ],
        },
        {
          title: "商户付费信息",
          children: [
            {
              title: "应收金额",
              dataIndex: "shoud_charge_without_tax",
              key: "shoud_charge_without_tax",
              width: 150,
              ellipsis: true,
              scopedSlots: { customRender: "shoud_charge" },
            },
            {
              title: "实收金额",
              dataIndex: "real_charge",
              key: "real_charge",
              width: 100,
              ellipsis: true,
            },
            {
              title: "收账平台",
              dataIndex: "charge_platform",
              key: "charge_platform",
              width: 240,
              ellipsis: true,
            },
            {
              title: "历史收款",
              key: "set",
              dataIndex: "set",
              scopedSlots: { customRender: "check" },
              width: 100,
            },
          ],
        },
        {
          title: "备注",
          key: "remark",
          dataIndex: "remark",
          ellipsis: true,
          width: 200,
          scopedSlots: { customRender: "remark" },
        },
      ],
      searchColumns: [
        {
          title: "#",
          dataIndex: "rank",
          width: 50,
        },
        {
          title: "商户名",
          dataIndex: "sname",
          key: "sname",
          width: 250,
          ellipsis: true,
          scopedSlots: { customRender: "sname" },
        },
        {
          title: "登录信息",
          dataIndex: "user_name",
          key: "user_name",
          width: 200,
          ellipsis: true,
        },
        {
          title: "商户状态",
          dataIndex: "business_status",
          key: "business_status",
          width: 150,
          ellipsis: true,
          scopedSlots: { customRender: "business_status" },
        },
        {
          title: "合同状态",
          dataIndex: "contract_status",
          key: "contract_status",
          width: 150,
          ellipsis: true,
          scopedSlots: { customRender: "contract_status" },
        },
        {
          title: "新/续约",
          dataIndex: "is_renewal_business",
          key: "is_renewal_business",
          width: 150,
          ellipsis: true,
          scopedSlots: { customRender: "is_renewal_business" },
        },
        {
          title: "付款状态",
          dataIndex: "payment_status",
          key: "payment_status",
          width: 150,
          ellipsis: true,
          scopedSlots: { customRender: "payment_status" },
        },
      ],
      billColumns: [
        {
          title: "创建收据时间",
          dataIndex: "create_time",
          key: "create_time",
          width: 150,
          scopedSlots: { customRender: "create_time" },
        },
        {
          title: "已付时间",
          dataIndex: "complete_time",
          key: "complete_time",
          width: 150,
          scopedSlots: { customRender: "complete_time" },
        },
        {
          title: "Operation",
          key: "set",
          dataIndex: "set",
          scopedSlots: { customRender: "operation" },
          width: 100,
        },
      ],
      position: [
        {
          label: "店员",
          value: "Clerk",
        },
        {
          label: "经理",
          value: "Manager",
        },
        {
          label: "店长",
          value: "Store_manager",
        },
        {
          label: "GM",
          value: "GM",
        },
        {
          label: "老板",
          value: "Boss",
        },
      ],
      resetaurant_type: [
        {
          label: "Szechuan",
          value: "Szechuan",
        },
        {
          label: "Chinese BBQ",
          value: "Chinese BBQ",
        },
        {
          label: "Dim Sum",
          value: "Dim Sum",
        },
        {
          label: "American Chinese",
          value: "American Chinese",
        },
        {
          label: "Hot Pot",
          value: "Hot Pot",
        },
        {
          label: "Lzakaya",
          value: "Lzakaya",
        },
        {
          label: "Sushi",
          value: "Sushi",
        },
        {
          label: "Ramen",
          value: "Ramen",
        },
        {
          label: "Hibachi",
          value: "Hibachi",
        },
        {
          label: "Other Japanese Food",
          value: "Other Japanese Food",
        },
        {
          label: "Korean BBQ",
          value: "Korean BBQ",
        },
        {
          label: "Other Korean Food",
          value: "Other Korean Food",
        },
        {
          label: "Breakfast & Brunch",
          value: "Breakfast & Brunch",
        },
        {
          label: "Coffee & Drink",
          value: "Coffee & Drink",
        },
        {
          label: "Dessert",
          value: "Dessert",
        },
        {
          label: "Bars",
          value: "Bars",
        },
        {
          label: "Karaoke",
          value: "Karaoke",
        },
      ],
      ethnicity: [
        {
          label: "Chinese",
          value: "Chinese",
        },
        {
          label: "Korean",
          value: "Korean",
        },
        {
          label: "Japanese",
          value: "Japanese",
        },
        {
          label: "Asian",
          value: "Asian",
        },
        {
          label: "American",
          value: "American",
        },
        // {
        //   label: "African American",
        //   value: "African American",
        // },
        {
          label: "European",
          value: "European",
        },
        {
          label: "Hispanic",
          value: "Hispanic",
        },
        {
          label: "Indian",
          value: "Indian",
        },
        {
          label: "Others",
          value: "Others",
        },
      ],
      service: [
        {
          label: "Waitlist: $79",
          value: "Waitlist: $79",
        },
        {
          label: "Reservation: $79",
          value: "Reservation: $79",
        },
        {
          label: "W&R: $79",
          value: "W&R: $79",
        },
        {
          label: "Online Order: 1%",
          value: "Online Order: 1%",
        },
        {
          label: "Scan Table: 1%",
          value: "Scan Table: 1%",
        },
        {
          label: "Scan Table: $79",
          value: "Scan Table: $79",
        },
        {
          label: "Team Up: 1%",
          value: "Team Up: 1%",
        },
        {
          label: "Emenu: $29",
          value: "Emenu: $29",
        },
        {
          label: "Survey: Free",
          value: "Survey: Free",
        },
        {
          label: "Pass: Free",
          value: "Pass: Free",
        },
      ],
      business_status: [
        {
          label: "暂无",
          value: "0",
        },
        {
          label: "物料准备中",
          value: "1",
        },
        {
          label: "等待安装",
          value: "2",
        },
        {
          label: "等待需求",
          value: "3",
        },
        {
          label: "已安装",
          value: "4",
        },
        {
          label: "已上线",
          value: "5",
        },
        {
          label: "沉睡中",
          value: "6",
        },
        {
          label: "废弃账号",
          value: "7",
        },
      ],
      contract_status: [
        {
          label: "暂无",
          value: "0",
        },
        {
          label: "签约中",
          value: "1",
        },
        {
          label: "已签合同",
          value: "2",
        },
      ],
      payment_status: [
        {
          label: "暂无",
          value: "0",
        },
        {
          label: "试用期",
          value: "1",
        },
        {
          label: "未付款",
          value: "2",
        },
        {
          label: "已付款",
          value: "3",
        },
        {
          label: "已欠费",
          value: "4",
        },
      ],
      sales_manager: [
        {
          label: "Joe",
          value: "Joe",
        },
        {
          label: "Hank",
          value: "Hank",
        },
        {
          label: "Jay",
          value: "Jay",
        },
        {
          label: "Scott",
          value: "Scott",
        },
        {
          label: "Kylin",
          value: "Kylin",
        },
        {
          label: "Dave",
          value: "Dave",
        },
        {
          label: "Jennie",
          value: "Jennie",
        },
      ],
      contract_type: [
        {
          label: "Monthly",
          value: "Monthly",
        },
        {
          label: "Yearly",
          value: "Yearly",
        },
      ],
      duty_type: [
        {
          label: "Operate",
          value: "Operate",
          children: [
            {
              label: "Operate Menu",
              value: "Operate Menu",
            },
            {
              label: "Operate Setting",
              value: "Operate Setting",
            },
            {
              label: "Operate Material",
              value: "Operate Material",
            },
          ],
        },
        {
          label: "Product",
          value: "Product",
          children: [
            {
              label: "Product BUG",
              value: "Product BUG",
            },
            {
              label: "Product Demand",
              value: "Product Demand",
            },
            {
              label: "Product Train",
              value: "Product Train",
            },
          ],
        },
        {
          label: "Visit",
          value: "Visit",
        },
        {
          label: "Others",
          value: "Others",
        },
      ],
      tabList: [
        {
          key: "0",
          tab: "正在处理",
        },
        {
          key: "1",
          tab: "已完成",
        },
      ],
      business_level: [
        {
          label: "普通",
          value: "0",
        },
        {
          label: "中级",
          value: "1",
        },
        {
          label: "高级",
          value: "2",
        },
      ],
      business_type: [
        {
          label: "单店",
          value: "0",
        },
        {
          label: "连锁店",
          value: "1",
        },
      ],
      business_tag: [
        {
          label: "好说话",
          value: "0",
        },
        {
          label: "事儿多",
          value: "1",
        },
        {
          label: "比较暴躁",
          value: "2",
        },
        {
          label: "经常改菜单",
          value: "3",
        },
        {
          label: "经常欠钱",
          value: "4",
        },
      ],
    };
  },
  mounted() {
    this.is_loading = true;
    this.initData({
      pageNo: 1,
      pageSize: 50,
      ...this.searchList,
      cb: this.set_loading_cb,
    });
    this.unid(this.columns, this.searchItems);
    this.handleSynchronStore(); //同步数据
  },
  computed: {
    ...mapGetters({
      // loading: 'business/getLoading',
      content: "business/getConetent",
      dutyContent: "business/getDutyContent",
      billContent: "business/getBillContent",
      bill: "business/getBill",
    }),
    pagination() {
      return {
        total: this.content.total || 0,
        pageSize: this.content.pageSize || 50,
        current: this.content.pageNo || 1,
      };
    },
    dutyPagination() {
      return {
        total: this.dutyContent.total || 0,
        pageSize: this.dutyContent.pageSize || 20,
        current: this.dutyContent.pageNo || 1,
      };
    },
    statusCount() {
      if (this.dutyContent.statusTotal) {
        return {
          status0: this.dutyContent.statusTotal.status0,
          status1: this.dutyContent.statusTotal.status1,
        };
      } else {
        return { status0: 0, status1: 0 };
      }
    },
    contractPrice() {
      let nPrice = 0;
      if (this.setObj.service) {
        const service = this.setObj.service.split(",");
        service.map((item) => {
          if (item.indexOf("$") !== -1) {
            let price = item.substr(item.indexOf("$") + 1, item.length);
            nPrice += Number(price);
          }
        });
      }
      if (this.setObj.contract_type == "Monthly") {
        return nPrice;
      } else if (this.setObj.contract_type == "Yearly") {
        return nPrice * 12;
      } else {
        return 0;
      }
    },
  },
  watch: {
    bill: function (val, oldval) {
      const _sid = this.billObj.sid;
      this.billObj = { ...val.data };
      this.billObj.sid = _sid;
      this.billObj.charge_list = this.billObj.charge_list
        ? JSON.parse(this.billObj.charge_list)
        : [];
      this.myChargeList = this.myChargeList.concat(this.billObj.charge_list);
      this.billVisible = true;
    },
  },
  methods: {
    goToNewPage() {
      this.$router.push({ path: "/mrrAmount" });
    },
    set_loading_cb() {
      this.is_loading = false;
    },
    set_duty_loading_cb() {
      this.is_duty_loading = false;
    },
    // 接口
    ...mapActions({
      initData: "business/initData", //搜索接口
      updateTable: "business/updateTable", //更新数据接口
      updateStatus: "business/updateStatus", //更新状态接口
      initDutyData: "business/initDutyData", //查询任务列表
      addDuty: "business/addDuty", //添加任务接口
      updateDuty: "business/updateDuty", //更新任务接口
      updateDutyStatus: "business/updateDutyStatus", //更新任务状态接口
      synchronStore: "business/synchronStore", //同步商户信息接口
      initBillData: "business/initBillData", //历史账单接口
      getBill: "business/getBill", //获取账单
      addBill: "business/addBill", //更新账单接口
      updateBill: "business/updateBill", //更新账单接口
      delBill: "business/delBill", //删除账单接口
    }),
    // 同步商户信息
    handleSynchronStore() {
      this.synchronStore({});
    },
    // 搜索
    handleTableSearch() {
      let obj = { ...this.searchList };
      if (obj.time_range) {
        let first = obj.time_range[0];
        let last = obj.time_range[1];
        let _first = Date.parse(new Date(first));
        let _last = Date.parse(new Date(last));
        obj.time_range = [_first, _last];
      }
      this.is_loading = true;

      this.initData({
        pageNo: 1,
        pageSize: 50,
        ...obj,
        cb: this.set_loading_cb,
      });
      this.searchStatus = true;
    },
    // 重置搜索条件
    handleTableReset() {
      let obj = { ...this.searchList };
      for (const key in obj) {
        if (key == "time_range") {
          obj[key] = [];
        } else {
          obj[key] = "";
        }
      }
      this.searchList = obj;
      this.is_loading = true;
      this.initData({
        pageNo: 1,
        pageSize: 50,
        ...this.searchList,
        cb: this.set_loading_cb,
      });
      this.searchStatus = false;
    },
    // 点击分页
    handleTableChange(pagination) {
      this.is_loading = true;
      this.initData({
        pageNo: pagination.current,
        pageSize: pagination.pageSize,
        ...this.searchList,
        cb: this.set_loading_cb,
      });
    },
    // 任务列表分页
    handelDutyTableChange(page) {
      this.is_duty_loading = true;
      this.initDutyData({
        sid: this.setDutyObj.sid,
        pageNo: page,
        pageSize: this.dutyPagination.pageSize,
        status: this.active_tab_key,
        cb: this.set_duty_loading_cb,
      });
    },
    // 打开任务弹窗
    handleDutySetting(record) {
      this.is_duty_loading = true;
      this.active_tab_key = "0";
      this.setDutyObj = {
        sid: record.sid,
        sname: record.sname,
        sales_manager: record.sales_manager,
      };
      this.initDutyData({
        sid: record.sid,
        pageNo: 1,
        pageSize: 20,
        status: this.active_tab_key,
        cb: this.set_duty_loading_cb,
      });
      this.dutyListVisible = !this.dutyListVisible;
    },
    // 打开任务编辑弹窗
    handelUpdateDuty(duty) {
      this.dutyObj = { ...duty };
      this.isMore = !this.isMore;
    },
    // 操作任务等级
    clickStar(i) {
      this.dutyObj.duty_level = i;
    },
    // 切换任务状态
    onTabChange(key) {
      this.is_duty_loading = true;
      this.active_tab_key = key;
      this.initDutyData({
        sid: this.setDutyObj.sid,
        pageNo: 1,
        pageSize: 20,
        status: key,
        cb: this.set_duty_loading_cb,
      });
    },
    // 更新任务状态
    handelUpdateDutyStatus(key, id) {
      this.is_duty_loading = true;
      const params = {
        id,
        status: key,
        complete_user: window.localStorage.getItem("user_name"),
        complete_time: key == 1 ? Date.parse(new Date()) : "",
      };
      this.updateDutyStatus({
        ...params,
        cb: this.set_duty_loading_cb,
        afterdata: {
          pageNo: this.pagination.current,
          pageSize: this.pagination.pageSize,
          status: this.active_tab_key,
          sid: this.setDutyObj.sid,
        },
      });
    },
    // 添加任务
    handleDutySubmit() {
      this.is_duty_loading = true;
      const obj = { ...this.dutyObj };
      const _cb = {
        cb: this.set_duty_loading_cb,
        afterdata: {
          pageNo: this.pagination.current,
          pageSize: this.pagination.pageSize,
          status: this.active_tab_key,
          sid: this.setDutyObj.sid,
        },
      };
      if (obj.id) {
        this.updateDuty({
          ...obj,
          ..._cb,
        });
      } else {
        let _obj = {
          sid: this.setDutyObj.sid,
          status: "0",
          publish_user: window.localStorage.getItem("user_name"),
          create_time: Date.parse(new Date()),
        };
        let params = { ...obj, ..._obj };
        this.addDuty({
          ...params,
          ..._cb,
        });
      }
      this.onDutyCancel();
    },
    onDutyCancel() {
      this.dutyObj = {
        duty_level: undefined,
        duty_type: undefined,
        duty_content: undefined,
      };
      this.isMore = false;
    },
    // 打开编辑弹窗
    handleSetting(record, option) {
      this.setObj = { ...record };
      this.setObj.resetaurant_type = this.setObj.resetaurant_type
        ? this.setObj.resetaurant_type.split(",")
        : [];
      option ? (this.isContract = true) : (this.isContract = false);
      this.setObj.contract_stime = this.formatDateAmerica(this.setObj.contract_stime);
      this.setObj.contract_etime = this.formatDateAmerica(this.setObj.contract_etime);
      this.setObj.free_trail_start_time = this.formatDateAmerica(this.setObj.free_trail_start_time);
      this.setObj.free_trail_end_time = this.formatDateAmerica(this.setObj.free_trail_end_time);
      this.visible = !this.visible;
    },
    // 状态更新
    handelUpdateStatus(key, sid, status_type) {
      this.is_loading = true;
      let obj;
      if (status_type === "business_status") {
        obj = { business_status: key };
      } else if (status_type === "contract_status") {
        obj = { contract_status: key };
      } else if (status_type === "payment_status") {
        obj = { payment_status: key };
      }
      this.updateStatus({
        sid,
        ...obj,
        cb: this.set_loading_cb,
        afterdata: {
          pageNo: this.pagination.current,
          pageSize: this.pagination.pageSize,
          ...this.searchList,
        },
      });
    },
    // 服务更新
    handelUpdateService(key, sid) {
      this.updateStatus({
        sid,
        service: key.join(","),
        cb: this.set_loading_cb,
        afterdata: {
          pageNo: this.pagination.current,
          pageSize: this.pagination.pageSize,
          ...this.searchList,
        },
      });
    },
    // 弹窗提交
    handleSubmit() {
      let obj = { ...this.setObj };
      let _obj = {
        contract_price: this.contractPrice,
        contract_stime: Date.parse(obj.contract_stime),
        contract_etime: Date.parse(obj.contract_etime),
        free_trail_start_time: Date.parse(obj.free_trail_start_time),
        free_trail_end_time: Date.parse(obj.free_trail_end_time),
      };
      let params = { ...obj, ..._obj };
      this.updateTable({
        ...params,
        afterdata: {
          pageNo: this.pagination.current,
          pageSize: this.pagination.pageSize,
          ...this.searchList,
        },
      });
      this.onClose();
    },
    // 弹窗关闭
    onClose() {
      this.visible = false;
    },
    onDutyClose() {
      this.dutyListVisible = false;
      this.onDutyCancel();
    },
    // 编辑账单
    handelBill(record, status) {
      this.myChargeList = [];
      if (status === 0) {
        this.billObj.sid = record.sid;
        this.getBill({
          sid: record.sid,
          status: status,
        });
      } else {
        this.billObj = { ...record };
        this.myChargeList = this.billObj.charge_list
          ? JSON.parse(this.billObj.charge_list)
          : [];
        this.billListVisible = false;
        this.billVisible = true;
      }
    },
    amountChange(e, type, i) {
      if (type === "amount") {
        const val = e.target.value;
        if (!Number(val)) {
          message.warning("请输入数字");
          return;
        }
      } else if (type === "tax") {
        i.apply_tax = e.target.checked ? 1 : 0;
      }

      let tip = 0;
      let total = 0;
      this.myChargeList.map((item) => {
        total += Number(item.amount);
        if (item.apply_tax === 1) {
          tip += Number(item.amount) * 0.08875;
        }
      });
      total += tip;
      this.billObj.tip = tip.toFixed(2);
      this.billObj.total_amount = total.toFixed(2);
    },
    // 移除服务项
    removeDomain(item) {
      let index = this.myChargeList.indexOf(item);
      if (index !== -1) {
        this.myChargeList.splice(index, 1);
      }
    },
    // 添加服务项
    addDomain() {
      this.myChargeList.push({
        name: "",
        amount: "",
        apply_tax: 0,
      });
    },
    // 删除账单
    handledeleteBill(id, sid) {
      this.delBill({
        id,
        sid,
        cb: this.set_loading_cb,
        afterdata: {
          pageNo: this.pagination.current,
          pageSize: this.pagination.pageSize,
        },
      });
      this.billVisible = false;
    },
    // 更新账单
    handleBillSubmit(id) {
      let obj = { ...this.billObj };
      let _list = this.myChargeList;
      obj.charge_list = JSON.stringify(_list);
      let _obj = {
        status: 0,
        real_amount: 0,
        charge_platform: "",
      };
      const params = { ...obj, ..._obj };
      if (id) {
        this.updateBill({
          id,
          ...params,
          cb: this.set_loading_cb,
          afterdata: {
            pageNo: this.pagination.current,
            pageSize: this.pagination.pageSize,
          },
        });
      } else {
        this.addBill({
          ...params,
          create_time: Date.parse(new Date()),
          cb: this.set_loading_cb,
          afterdata: {
            pageNo: this.pagination.current,
            pageSize: this.pagination.pageSize,
          },
        });
      }
      this.billVisible = false;
    },
    // 更新账单状态
    handleBillStatus(sts, type) {
      this.is_loading = true;
      if (type) {
        this.billObj.charge_platform = type;
        this.billObj.complete_time = Date.parse(new Date());
      }
      this.billObj.status = sts;
      this.billObj.charge_list = JSON.stringify(this.myChargeList);
      this.updateBill({
        ...this.billObj,
        type: sts === 0,
        cb: this.set_loading_cb,
        afterdata: {
          pageNo: this.pagination.current,
          pageSize: this.pagination.pageSize,
        },
      });
      this.billVisible = this.billListVisible = false;
    },
    // 打开历史账单列表
    billList(sid) {
      this.initBillData({
        sid,
        status: 1,
        pageNo: 1,
        pageSize: 20,
      });
      this.billListVisible = !this.billListVisible;
    },
    // 打开备注弹窗
    handleremarkSetting(record) {
      this.setObj = { ...record };
      this.remarkVisible = true;
    },
    // 更新备注
    handleRemarkOk() {
      this.updateTable({
        ...this.setObj,
        afterdata: {
          pageNo: this.pagination.current,
          pageSize: this.pagination.pageSize,
          ...this.searchList,
        },
      });
      this.remarkVisible = false;
    },
    // 备注
    remark(val, option) {
      let curType = undefined;
      if (option === "level") {
        curType = this.business_level;
      } else if (option === "type") {
        curType = this.business_type;
      } else if (option === "tag") {
        curType = this.business_tag;
      }
      if (val === "") {
        return "";
      }
      for (let index = 0; index < curType.length; index++) {
        if (curType[index].value == val) {
          return curType[index].label + "  ";
        }
      }
      return "";
    },
    // 多维数组children的摊平
    unid(arr, result) {
      for (let item of arr) {
        if (Object.prototype.hasOwnProperty.call(item, "children")) {
          this.unid(item.children, result);
        } else {
          result.push(item);
        }
      }
      return result;
    },
    //格式化为美东时间
    formatDatetoAmerica(time) {
      return moment(Number(time))
        .tz("America/New_York")
        .format("YYYY-MM-DD hh:mm");
    },
    formatDateAmerica(time) {
      return moment(Number(time)).tz("America/New_York").format("YYYY-MM-DD");
    },
    chargeColor(record) {
      const nowDate = Date.parse(new Date());
      const a = moment(Number(record.free_trail_end_time))
        .subtract(14, "days")
        .format("YYYY-MM-DD");
      const b = moment(a).valueOf();
      const c = moment(Number(record.contract_etime))
        .subtract(30, "days")
        .format("YYYY-MM-DD");
      const d = moment(c).valueOf();
      this.$nextTick(() => {
        if (
          (b < nowDate || d < nowDate) &&
          record.shoud_charge_without_tax > 0
        ) {
          this.$refs[record.id].$el.style.color = "red";
        }
      });
      return record.shoud_charge_without_tax;
    },
  },
};
</script>
