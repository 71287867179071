import fetchApi from '../../kits/newFetch';
import {message} from 'ant-design-vue'

// initial state
const state = {
    msg_opts: '',
    loading: false,
    content: {}
};

// getters
const getters = {
    getLoading(state) {
        return state.loading;
    },
    getConetent(state) {
        return state.content;
    },
    get_app_msg(state) {
        return state.msg_opts;
    }
};

// actions
const actions = {
    initData({ commit, rootState, rootGetters }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:8181' : '';
            fetchApi(
                { commit },
                {
                    request_name: 'get_table',
                    params: post_data,
                    aj_host
                }
            )
            .then((res) => {
                if (res && res.data && res.data.data) {
                    commit("APP_SET_CONTENT", {
                        content: res.data.data
                    });

                    post_data.cb && post_data.cb();
                }
            }).catch((e) => {
                console.log(e);
            });
    },
    updateTable({ commit, rootState, rootGetters }, post_data){
        let aj_host = rootState.debug ? 'http://127.0.0.1:8181' : '';
            fetchApi(
                { commit },
                {
                    request_name: 'update_table',
                    params: post_data,
                    aj_host
                }
            )
            .then((res) => {
                if (res.data.code == 100000) {
                    message.success('update is success!');
                    actions.initData({ commit, rootState, rootGetters }, post_data.afterdata)
                }else{
                    message.success('update is failed!');
                }
            }).catch((e) => {
                message.success('update is failed!');
                console.log(e);
            });
    },
    synchronStore({ commit, rootState, rootGetters }, post_data){
        let aj_host = rootState.debug ? 'http://127.0.0.1:8181' : '';
            fetchApi(
                { commit },
                {
                    request_name: 'synchron_store',
                    params: post_data,
                    aj_host
                }
            )
            .then((res) => {
                if (res.data.code == 100000) {
                    message.success('synchron is success!');
                }else{
                    message.success('synchron is failed!');
                }
            }).catch((e) => {
                message.success('synchron is failed!');
            });
    }
};

// mutations
const mutations = {
    APP_SET_IF_LOADING(state, _data) {
        state.is_loading = _data.is_loading;
    },
    APP_SET_CONTENT(state, _data) {
        state.content = _data.content;
    },
    APP_SET_GLOBAL_DAT(state, _data) {
        state.sid = _data.sid;
        state.uid = _data.uid;
        state.role = _data.role;
        state.sname = _data.sname;
        state.slogo = _data.slogo;
        state.token = _data.token;
    },
    APP_SET_MSG(state, _data) {
        state.msg_opts = _data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
