import Vue from 'vue';
import Router from 'vue-router';
import store from './store';

import MrrAmount from './views/MrrAmount.vue';
import Business from './views/Business.vue';
import Welcome from './views/Welcome.vue';
import Login from './views/Login.vue';
Vue.use(Router);

const router = new Router({
    mode: 'hash',
    linkExactActiveClass: 'is-active',
    scrollBehavior: () => ({ y: 0 }),
    routes: [
        { path: '/mrrAmount', component: MrrAmount },
        { path: '/business', component: Business },
        { path: '/welcome', component: Welcome },
        { path: '/login', component: Login },
        { path: '*', redirect: '/login' }
    ]
});

router.beforeEach(async (to, from, next) => {
    //to prevent loading animation keeps running when switching router
    store.commit('app/APP_SET_IF_LOADING', { is_loading: false });
    next();
});

export default router;
