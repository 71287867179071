import fetchApi from '../../kits/newFetch';

// initial state
const state = {
    msg_opts: '',
    loading: false,
    content: [],
    mrr_content: []
};

// getters
const getters = {
    getLoading(state) {
        return state.loading;
    },
    getContent(state) {
        return state.content;
    },
    getMrrContent(state) {
        return state.mrr_content;
    },
    get_app_msg(state) {
        return state.msg_opts;
    }
};

// actions
const actions = {
    initData({ commit, rootState, rootGetters }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:8181' : '';
        fetchApi(
            { commit },
            {
                request_name: 'get_mrr_amount',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                if (res && res.data && res.data.data) {
                    commit("APP_SET_CONTENT", {
                        content: res.data.data.data
                    });
                }
            })
    },
    getMrrAmount({ commit, rootState, rootGetters }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:8181' : '';
        fetchApi(
            { commit },
            {
                request_name: 'get_after_mrr_amount',
                params: post_data,
                aj_host
            }
        )
            .then((res) => {
                if (res && res.data) {
                    commit("APP_SET_MRR_CONTENT", {
                        mrr_content: res.data.data
                    });
                    post_data.cb && post_data.cb();
                }
            })
    }
};

// mutations
const mutations = {
    APP_SET_IF_LOADING(state, _data) {
        state.is_loading = _data.is_loading;
    },
    APP_SET_CONTENT(state, _data) {
        state.content = _data.content;
    },
    APP_SET_MRR_CONTENT(state, _data) {
        state.mrr_content = _data.mrr_content;
    },
    APP_SET_MSG(state, _data) {
        state.msg_opts = _data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    strict: true
};
