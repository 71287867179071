<template>
  <div class="store-list">
    <!-- 表格区域 -->
    <div class="table-box">
      <a-list
        style="border: 1px solid #e8e8e8"
        :grid="{ gutter: 0, column: 12 }"
        :data-source="content.concat(mrr_content)"
      >
        <a-list-item slot="renderItem" slot-scope="item">
          <a-card :title="'$' + item.income">
            {{ item.date }}
          </a-card>
        </a-list-item>
        <div style="text-align: center" slot="header">{{ formatDate() }}</div>
      </a-list>
    </div>
    <div class="loading_layer" v-if="is_loading">
      <a-spin />
    </div>
  </div>
</template>
<style scoped>
.table-box {
  margin: 50px 20px;
}
.loading_layer {
  text-align: center;
  vertical-align: middle;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0%;
  left: 0%;
  line-height: 500px;
  background: #fff;
  z-index: 100;
  opacity: 0.5;
}
</style>
<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

export default {
  name: "mrrAmount",
  data() {
    return {
      is_loading: false
    };
  },
  mounted() {
    this.is_loading = true;
    this.initData();
    this.getMrrAmount({
      cb: this.set_loading_cb,
    })
  },
  computed: {
    ...mapGetters({
      content: "mrrAmount/getContent",
      mrr_content: "mrrAmount/getMrrContent",
    }),
  },
  methods: {
    set_loading_cb() {
      this.is_loading = false;
    },
    // 接口
    ...mapActions({
      initData: "mrrAmount/initData", //搜索接口
      getMrrAmount: "mrrAmount/getMrrAmount",
    }),
    // 格式化时间
    formatDate() {
      let _date = new Date();
      let y = _date.getFullYear();
      const data = this.content.concat(this.mrr_content);
      let arr = 0;
      data.map(item => {
          arr += item.income;
      })
      return y + '年（ARR金额：$' + arr + '）';
    },
  },
};
</script>
