require('es6-promise').polyfill(); //es6 promise
require('isomorphic-fetch'); //fetch

import Vue from 'vue';
import App from './views/App.vue';
import store from './store/index.js';
import router from './router';
import VueLazyload from 'vue-lazyload';
import antDesign from "ant-design-vue";
import 'ant-design-vue/dist/antd.css'

Vue.use(VueLazyload, {
    preLoad: 1.3,
    attempt: 1
});
//ant-design-vue模块按需引入
Vue.prototype.$message = antDesign.message
Vue.use(antDesign)
export function createApp() {
    const app = new Vue({
        el: '#application',
        router,
        store,
        render: (h) => h(App)
    });

    return { app, router };
}

window.onload = () => createApp();
