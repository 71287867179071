import fetchApi from '../../kits/newFetch';

// initial state
const state = {
    token:''
};

// getters
const getters = {
    
};

// actions
const actions = {
    do_login_aj ({ commit, rootState, dispatch }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:8181' : '';
        fetchApi({ commit }, {
            request_name: 'loginIn',
            params: {
                user_name: post_data.user_name,
                pswd: post_data.pswd
            },
            aj_host
        }).then(((res) => {
            if (res.data.code == 100000) {
                window.localStorage.setItem('token', res.data.data.token);
                window.localStorage.setItem('user_name', res.data.data.user_info[0].user_name);
                post_data.router.replace({ path: '/welcome' });
                // post_data.router.replace({ path: '/business' });
            }
        })).catch(() => null);
    }
};

// mutations
const mutations = {
    APP_SET_IF_LOADING(state, _data) {
        state.is_loading = _data.is_loading;
    },
    APP_SET_CONTENT(state, _data) {
        state.content = _data.content;
    },
    APP_SET_GLOBAL_DAT(state, _data) {
        state.sid = _data.sid;
        state.uid = _data.uid;
        state.role = _data.role;
        state.sname = _data.sname;
        state.slogo = _data.slogo;
        state.token = _data.token;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
