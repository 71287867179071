import querystring from 'querystring';

const interface_list = {
    get_business_table: { url: '/weapp/business/get_business_table/get', method: 'get' },
    get_duty_count: { url: '/weapp/business/get_duty_count/get', method: 'get' },
    update_business_table: { url: '/weapp/business/update_business/get', method: 'get' },
    update_status: { url: '/weapp/business/update_status/get', method: 'get' },
    get_duty_table: { url: '/weapp/business/get_duty_table/get', method: 'get' },
    add_duty: { url: '/weapp/business/add_duty/get', method: 'get' },
    update_duty: { url: '/weapp/business/update_duty/get', method: 'get' },
    update_duty_status: { url: '/weapp/business/update_duty_status/get', method: 'get' },
    get_table: { url: '/weapp/business/get_table/get', method: 'get' },
    loginIn: { url: '/weapp/business/login/get', method: 'get' },
    update_table:{ url:'/weapp/business/update/get', method: 'get' },
    synchron_store:{ url:'/weapp/business/synchron/get', method: 'get' },
    get_bill_table: { url: '/weapp/business/get_bill_table/get', method: 'get' },
    get_bill_by_id: { url: '/weapp/business/get_bill_by_id/get', method: 'get' },
    add_bill: { url: '/weapp/business/add_bill/get', method: 'get' },
    update_bill: { url: '/weapp/business/update_bill/get', method: 'get' },
    delete_bill: { url: '/weapp/business/delete_bill/get', method: 'get' },
    get_mrr_amount: { url: '/weapp/mrrAmount/get_mrr_amount/get', method: 'get'},
    get_after_mrr_amount: { url: '/weapp/mrrAmount/get_after_mrr_amount/get', method: 'get'}
};

function fetchApi({ request_name, params, customHeaders = {}, aj_host = '' }) {
    const conf = JSON.parse(JSON.stringify(interface_list[request_name]));
    const token = window.localStorage.getItem('token') || '';

    if (!conf) {
        return Promise.reject({
            code: 0,
            msg: '',
            data: {
                code: 100010,
                msg: 'no ajax register',
                data: {}
            }
        });
    }

    const headers = new Headers();

    Object.keys(customHeaders).forEach((k) => {
        headers.append(k, customHeaders[k]);
    });

    headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
    headers.append('Authorization', 'Bearer ' + token);

    const fetch_optins = {
        method: conf.method,
        headers,
        credentials: 'omit'
    };

    if (conf.method == 'get') {
        conf.url = conf.url + '?' + querystring.stringify(params);
    } else if (conf.method == 'post') {
        const searchParams = Object.keys(params)
            .map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
            })
            .join('&');

        fetch_optins.body = searchParams;
    }

    return fetch(aj_host + conf.url, fetch_optins)
        .then((response) => {
            if (response.status >= 400) {
                throw new Error('Bad response from server');
            }

            if (response.headers.get('Authorization')) {
                let token = response.headers.get('Authorization').split(' ')[1];
                if (token) {
                    window.localStorage.setItem('token', token);
                }
            }
            return response.json();
        })
        .then((data) => {
            if (data.code == -1) {
                Promise.reject({
                    code: 0,
                    msg: '',
                    data: {
                        code: 100010,
                        msg: response.error,
                        data: {}
                    }
                });
                return;
            }

            if (data.data.code === 100000) {
                return Promise.resolve(data);
            } else if (data.data.code === 100002) {
                window.localStorage.removeItem('sid');
                window.localStorage.removeItem('token');
                window.location.replace('/login');
                return Promise.reject(data);
            } else {
                return Promise.reject(data);
            }
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}

export default ({ commit }, opts) => {
    return fetchApi(opts)
        .then((data) => {
            return Promise.resolve(data);
        })
        .catch((res) => {
            commit && commit('app/APP_SET_MSG', { msg: res.data.msg, type: 'error' }, { root: true });
            return Promise.reject(res);
        });
};
